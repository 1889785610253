




import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import { AppSettingsModel } from "@/@types/model";

@Component({ name: "VClock" })
export default class VClock extends Vue {
  currentTime: string | null = null;

  @Get("app/settings")
  readonly appSettings!: AppSettingsModel;

  created() {
    this.currentTime = this.getTime();
    setInterval(() => (this.currentTime = this.getTime()), 1000);
  }

  getTime() {
    const { timeFormat } = this.appSettings;
    if (timeFormat === 12) return moment().format("hh:mm:ss A");
    else return moment().format("HH:mm:ss");
  }
}
