import { ActionTree } from "vuex";
import { MediaState } from "./types";
import { RootState } from "../types";

import imagesService from "@/services/images.service";

export const actions: ActionTree<MediaState, RootState> = {
  async fetchMedia({ commit }) {
    commit("SET_IS_LOADING", true);

    const mediaList = await imagesService.getAll();
    commit("SET_MEDIA_LIST", mediaList);

    commit("SET_IS_LOADING", false);
  },

  async uploadMedia({ commit, state }, { file, imageType }) {
    commit("SET_IS_BUSY", true);
    try {
      const media = await imagesService.uploadImage(file, { imageType });
      commit("SET_MEDIA_LIST", [media, ...state.mediaList]);
    } catch (e) {
      console.log(e);
    } finally {
      commit("SET_IS_BUSY", false);
    }
  },

  async saveSelectMedia({ commit, state }) {
    if (!state.selectMedia) return;

    const { id, ...media } = state.selectMedia;
    try {
      await imagesService.updateById(id, media);
      const mediaList = state.mediaList.map((m) => (m.id !== id ? m : { ...m, ...media }));
      commit("SET_MEDIA_LIST", mediaList);
      commit("SET_SELECT_MEDIA", null);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteMedia({ commit, state }, { id }) {
    await imagesService.deleteById(id);
    const mediaList = state.mediaList.filter((m) => m.id !== id);
    commit("SET_MEDIA_LIST", mediaList);
  },

  clearMedia({ commit }) {
    commit("CLEAR_MEDIA");
  },
};
