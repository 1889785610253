

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "VLogo" })
export default class VLogo extends Vue {
  @Prop({ type: String, default: "140px" }) readonly width!: string;
  @Prop({ type: Boolean, default: false }) readonly mobile!: boolean;
  @Prop({ type: String, default: "40px" }) readonly mobileWidth!: string;
  @Prop(Object) readonly to!: object;
  @Prop(Boolean) readonly useTheme!: boolean;

  get localTheme() {
    if (this.useTheme) return this.$vuetify.theme.dark ? "light" : "dark";

    if (this.isLocal || this.isDevelopment) return "light";
    if (!this.$vuetify.theme.dark) return "dark";

    return "light";
  }

  handleClickLogo() {
    if (this.to) this.$router.push(this.to, () => {});
  }
}
