







import { Component, Vue } from "vue-property-decorator";
import { Sync } from "vuex-pathify";

import { NotifyModel } from "@/store/notifications/types";

@Component({ name: "VNetworkStatus" })
export default class VNetworkStatus extends Vue {
  @Sync("notifications/notify") notify!: NotifyModel | null;

  destroyed() {
    if (this.notify && this.notify.id === "network-status-online") return;
    this.notify = {
      id: "network-status-online",
      text: "Your internet connection was restored",
      type: "success",
      position: "bottom",
      manager: "message",
      timeout: -1,
    };
  }
}
