











































































































import Component, { mixins } from "vue-class-component";
import { Get } from "vuex-pathify";

import VNetworkStatusAlert from "@/components/VNetworkStatusAlert.vue";
import VLogo from "@/components/VLogo.vue";
import VClock from "@/components/VClock.vue";
import VLocaleDropdown from "@/components/VLocaleDropdown.vue";
import VProfileDropdown from "@/components/profile/VProfileDropdown.vue";
import VMenuPreview from "@/components/menus/VMenuPreview.vue";
import VAppMenuToolbar from "@/components/menus/VAppMenuToolbar.vue";
import VLeftNavigation from "@/components/VLeftNavigation.vue";
import VAppSettingsDropdown from "@/components/VAppSettingsDropdown.vue";
import VNewFeatures from "@/components/notifications/VNewFeatures.vue";
import VNotificationsDropdown from "@/components/notifications/VNotificationsDropdown.vue";

import Notifications from "@/mixins/notifications";
import Notify from "@/mixins/notify";
import { menuEvents } from "@/constants/events";

import { NetStatus } from "@/@types/common";
import { AppSettingsModel, UserModel } from "@/@types/model";
import { NotificationModel } from "@/store/notifications/types";

@Component({
  name: "DefaultLayout",
  components: {
    VNetworkStatusAlert,
    VLogo,
    VClock,
    VLocaleDropdown,
    VProfileDropdown,
    VAppMenuToolbar,
    VMenuPreview,
    VLeftNavigation,
    VAppSettingsDropdown,
    VNewFeatures,
    VNotificationsDropdown,
  },
})
export default class DefaultLayout extends mixins(Notifications, Notify) {
  drawer: boolean = false;
  mini: boolean = false;

  previewMenuURL: string | null = null;
  isPreviewDrawer: boolean = false;
  isMenuPreview: boolean = false;

  @Get("app/netStatus")
  readonly netStatus!: NetStatus;

  @Get("auth/user")
  readonly user!: UserModel;

  @Get("notifications/notifications")
  readonly notifications!: NotificationModel[];

  @Get("app/settings")
  readonly appSettings!: AppSettingsModel;

  get isBack() {
    const exclude = ["settings.first", "settings.venue"];
    const { name } = this.$route;
    return name && name.split(".").length !== 1 && !exclude.includes(name);
  }

  get isMenuListPage() {
    return this.$route.name === "menus";
  }

  get isMenuCreatePage() {
    return this.$route.name === "menus.create";
  }

  get isMenuEditPage() {
    return this.$route.name === "menus.edit";
  }

  get pageTitle() {
    return this.$t(this.$route.meta!.key || "Page");
  }

  get pageSupInfo() {
    return this.$t(this.$route.meta!.supInfo || "");
  }

  created() {
    this.drawer = this.$vuetify.breakpoint.width >= 1366;

    this.$emitter.on(menuEvents.SHOW_MENU_PREVIEW, this.handleShowPreviewDrawer);
  }

  destroyed() {
    this.$emitter.off(menuEvents.SHOW_MENU_PREVIEW, this.handleShowPreviewDrawer);
  }

  handleShowPreviewDrawer(menuId: number | string) {
    this.previewMenuURL = `${this.appSettings.customerURL}/${menuId}?mode=development`;
    this.isPreviewDrawer = true;
  }

  handleInputPreviewDrawer(value: boolean) {
    if (value) this.isMenuPreview = true;
  }

  handleTransitionEndPreviewDrawer() {
    if (!this.isPreviewDrawer) this.isMenuPreview = false;
  }
}
