























































import { v4 as uuid } from "uuid";
import { Component, Vue } from "vue-property-decorator";
import { Sync } from "vuex-pathify";

import { NotificationModel, NotifyModel } from "@/store/notifications/types";

@Component({ name: "VNotificationsDropdown" })
export default class VNotificationsDropdown extends Vue {
  @Sync("notifications/notifications")
  notifications!: NotificationModel[];

  @Sync("notifications/notify")
  notify!: NotifyModel;

  handleClickNotification(notification: NotificationModel) {
    if (notification.isNotify) {
      const notify: NotifyModel = {
        id: uuid(),
        text: notification.text,
        type: notification.type,
        position: "top",
        actions: notification.actions,
        manager: "message",
        timeout: 3000,
      };
      this.notify = notify;
      return;
    }
  }
}
