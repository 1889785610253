import store from "../store";

export default async (to, from, next) => {
  const { roles } = to.meta;
  if (!roles) return next();

  const user = store.getters["auth/user"];
  if (roles.length && !roles.includes(user.role.token)) return next({ path: "/" });

  next();
};
