import axios from "axios";

import catchErrors from "../helpers/catchErrors";
import * as qs from "../helpers/queryString";
import { API_URL } from "../config";

const getAll = async () => {
  const { data, status } = await axios.get(`${API_URL}/images`);
  return status === 200 && data ? data : [];
};

const uploadImage = catchErrors(
  async (file, options) => {
    const { imageType } = options;
    const queryString = qs.stringify({ imageType });

    const formData = new FormData();
    formData.append("file", file);
    const { data, status } = await axios.post(`${API_URL}/images/upload${queryString}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return status === 200 && data ? data : null;
  },
  { isThrow: true },
);

const updateById = async (id, media) => {
  const { status } = await axios.patch(`${API_URL}/images/${id}`, media);
  return status === 200;
};

const deleteById = async (id) => {
  const { status } = await axios.delete(`${API_URL}/images/${id}`);
  return status === 201;
};

export default {
  getAll,
  uploadImage,
  updateById,
  deleteById,
};
