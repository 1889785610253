var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-settings-theme"},[_c('span',{staticClass:"font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.$t("GlobalUI.Theme")))]),_c('v-item-group',{staticClass:"mx-auto row row--dense",attrs:{"mandatory":""},model:{value:(_vm.currentTheme),callback:function ($$v) {_vm.currentTheme=$$v},expression:"currentTheme"}},_vm._l((_vm.items),function(ref){
var key = ref.key;
var label = ref.label;
var icon = ref.icon;
return _c('v-col',{key:key,attrs:{"cols":"6"}},[_c('v-item',{attrs:{"value":key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"\n              v-card--group\n              py-4 py-md-3\n              px-4\n              text-center\n              position-relative\n              cursor-pointer\n              d-flex\n              align-center\n              justify-space-between\n            ",attrs:{"color":active ? 'primary' : ("grey " + (_vm.dark ? 'darken' : 'lighten') + "-3"),"dark":!_vm.dark && active,"rounded":"","flat":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(label)+" "),_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }