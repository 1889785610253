import { v4 as uuid } from "uuid";
import { MutationTree } from "vuex";
import { make } from "vuex-pathify";

import { NotificationsState } from "./types";

export const mutations: MutationTree<NotificationsState> = {
  ...make.mutations(["notify", "notifications"]),

  SET_NOTIFY(state, payload) {
    if (!payload) {
      state.notify = payload;
      return;
    }

    state.notify = {
      ...payload,
      id: payload.id ? payload.id : uuid(),
    };
  },
};
