

































































import { Component, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import { USER_NET_STATUS } from "@/config";
import { confirm } from "@/helpers/dialogs";

import profileService from "@/services/profile.service";

import { UserNetStatus } from "@/@types/config";
import { UserModel } from "@/@types/model";

@Component({ name: "VProfileDropdown" })
export default class VProfileDropdown extends Vue {
  @Get("auth/user")
  user!: UserModel;

  get userNetStatus() {
    return USER_NET_STATUS.filter((status) => status.key !== "OFFLINE");
  }
  get userStatusColor() {
    const { netStatus } = this.user;
    const userNetStatus = USER_NET_STATUS.find(({ key }) => key === netStatus) as UserNetStatus;
    return userNetStatus.color;
  }

  async handleChangeNetStatus(status: UserNetStatus) {
    const netStatus = status.key;

    await this.$store.dispatch("auth/updateUser", { netStatus });
    await profileService.update({ id: this.user.id, netStatus });
  }

  async logout() {
    const isConfirm = await this.$dialog.warning(
      confirm(undefined, this.$t("Messages.AreYouSureWantToLogOut")),
    );
    if (!isConfirm) return;

    await this.$store.dispatch("auth/logout");
    this.$router.push({ name: "login" });
  }
}
