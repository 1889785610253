import moment from "moment";

import store from "../store";

import { Language } from "@/store/countries/types";

export const capitalize = (value: string) => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const dateFormat = function (val: string, locale: string, format: string = "ll") {
  const l = locale || store.get("lang/locale");
  return moment(val).locale(l).format(format);
};

export const dateOrderFormat = (value: string, timeFormat: string) => {
  const tm = timeFormat || store.get("app/settings.timeFormat");
  const tmString = tm === 24 ? "HH:mm" : "hh:mm A";
  if (moment().isSame(value, "day")) {
    return moment(value).format(tmString);
  }
  return moment(value).format(`${tmString}, DD-MM-YYYY`);
};

export const dateHumanizeFormat = function (val: string, locale: string) {
  const l = locale || store.get("lang/locale");
  return moment(val).locale(l).fromNow();
};

export const visibleLanguage = function (value: string, languages: Language[]) {
  const l = languages || store.get("countries/languages") as Language[];
  const language = l.find((language) => language.code === value);
  return language ? language.label : `Language "${value}" not found`;
};

export const normalizePrice = function (value: number) {
  return Math.round(value * 10000000) / 10000000;
}
