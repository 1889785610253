import { UserModel } from "@/@types/model";

export interface AuthState {
  user: UserModel;
  token?: string | null;
}

export enum types {
  SAVE_TOKEN = "SAVE_TOKEN",
  FETCH_USER = "FETCH_USER",
  FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE = "FETCH_USER_FAILURE",
  UPDATE_USER = "UPDATE_USER",

  CLEAR_USER = "CLEAR_USER",
  LOGOUT = "LOGOUT",
}
