import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const DEFAULT_LOCALE = "en";
const FOLLBACK_LOCALE = DEFAULT_LOCALE;

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: FOLLBACK_LOCALE,
  messages: {},
});

export async function loadMessages(locale: string) {
  if (
    locale !== FOLLBACK_LOCALE &&
    Object.keys(i18n.getLocaleMessage(FOLLBACK_LOCALE)).length === 0
  ) {
    const messages = await import(/* webpackChunkName: '' */ `../lang/${FOLLBACK_LOCALE}`);
    i18n.setLocaleMessage(FOLLBACK_LOCALE, messages);
  }

  if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await import(/* webpackChunkName: '' */ `../lang/${locale}`);
    i18n.setLocaleMessage(locale, messages);
  }

  if (i18n.locale !== locale) {
    i18n.locale = locale;
  }

  window.dataLayer && window.dataLayer.push({ "interface.lang": locale });
}

export default i18n;
