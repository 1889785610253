import moment from "moment";
import { ActionTree } from "vuex";

import { MenusState, types } from "./types";
import { RootState } from "../types";

import menusService from "../../services/menus.service";

export const actions: ActionTree<MenusState, RootState> = {
  async fetchMenus({ commit }) {
    commit(types.REQUESTED_MENUS);
    try {
      const menus = await menusService.getAll({
        query: { with: "schedule" },
      });
      commit(types.REQUESTED_MENUS_SUCCEEDED, menus);
    } catch (error) {
      commit(types.REQUESTED_MENUS_FAILED, error.response);
    }
  },

  updateMenu({ commit }, { id, payload }) {
    commit(types.UPDATE_MENU, { id, payload });
  },

  setMenu({ state, commit }, menuId) {
    const menu = state.menus.find((menu) => menu.id === menuId);

    if (menu) {
      delete menu.schedule;
      commit("menu/MENU/SET_MENU", menu, { root: true });
      commit("menu/MENU/SET_CACHE_MENU", menu, { root: true });
    }
  },

  async toggleStatusMenu({ commit }, { id, isPublished, isEnabledToOrder }) {
    const payload = {
      isPublished,
      isEnabledToOrder: isPublished ? isEnabledToOrder : false,
    };
    await menusService.updateById(id, payload);
    commit(types.UPDATE_MENU, { id, payload });
  },
  async toggleEnabledOrderMenu({ commit }, { id, isEnabledToOrder }) {
    await menusService.updateById(id, { isEnabledToOrder });
    commit(types.UPDATE_MENU, { id, payload: { isEnabledToOrder } });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async duplicateMenu({ commit, rootState }, { updatedAt, schedule, ...menu }) {
    const { defaultLanguage } = rootState.app.settings;
    const duplicateMenu = {
      ...menu,
      title: {
        ...menu.title,
        [defaultLanguage]: "Copy of " + menu.title[defaultLanguage],
      },
      isPublished: false,
      isEnabledToOrder: false,
      createdAt: moment().format(),
    };
    const { menuId } = await menusService.duplicate(duplicateMenu);
    commit(types.ADD_MENU, {
      ...duplicateMenu,
      id: menuId,
    });
  },

  async archiveMenu({ commit }, menu) {
    const archivedAt = moment().format();
    const payload = {
      archivedAt,
      isPublished: false,
      isEnabledToOrder: false,
    };
    await menusService.updateById(menu.id, payload);
    commit(types.UPDATE_MENU, { id: menu.id, payload });
  },

  async unArchiveMenu({ commit }, menu) {
    await menusService.updateById(menu.id, { archivedAt: null });
    commit(types.UPDATE_MENU, {
      id: menu.id,
      payload: { archivedAt: null },
    });
  },

  async trashMenu({ commit }, menu) {
    const deletedAt = moment().format();
    const payload = {
      deletedAt,
      archivedAt: null,
      isPublished: false,
      isEnabledToOrder: false,
    };
    await menusService.updateById(menu.id, payload);
    commit(types.UPDATE_MENU, { id: menu.id, payload });
  },

  async unTrashMenu({ commit }, menu) {
    const payload = {
      deletedAt: null,
      archivedAt: null,
    };
    await menusService.updateById(menu.id, payload);
    commit(types.UPDATE_MENU, { id: menu.id, payload });
  },

  async deleteMenu({ commit }, menu) {
    await menusService.deleteById(menu.id);
    commit(types.DELETE_MENU, menu.id);
  },

  clearMenus({ commit }) {
    commit(types.CLEAR_MENUS);
  },
};
