import axios from "axios";

import * as qs from "../helpers/queryString";
import catchErrors from "../helpers/catchErrors";

import { SupplierModel } from "@/@types/model";
import { DeepPartial } from "@/@types/common";

import { API_URL } from "../config";

const getFieldsById = catchErrors<DeepPartial<SupplierModel>>(
  async (supplierId: string | number, fields: string[] = []) => {
    const { data, status } = await axios.post(`${API_URL}/suppliers/${supplierId}`, { fields });

    return status === 200 && data ? data : null;
  },
  { isThrow: true },
);

const saveFields = catchErrors<boolean>(
  async (supplierId: string | number, fields: string[], options = { query: null }) => {
    const { query } = options;
    const queryString = qs.stringify(query);

    const { status } = await axios.patch(
      `${API_URL}/suppliers/${supplierId}${queryString}`,
      fields,
    );

    return status === 200;
  },
  { isThrow: true },
);

export default { getFieldsById, saveFields };
