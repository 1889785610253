import { MutationTree } from "vuex";
import { OrdersState, types } from "./types";

export const mutations: MutationTree<OrdersState> = {
  [types.REQUESTED_ORDERS](state) {
    state.isOrdersLoading = true;
  },

  [types.REQUESTED_ORDERS_SUCCEEDED](state, payload) {
    state.orders = payload;
    state.isOrdersLoading = false;
  },

  [types.REQUESTED_ORDERS_FAILED](state, error) {
    state.isOrdersLoading = false;
    state.ordersError = error;
  },

  [types.ADD_ORDER](state, payload) {
    state.orders = [payload, ...state.orders];
  },

  [types.CLEAR_ORDERS](state) {
    state.orders = [];

    state.isOrdersLoading = false;
    state.ordersError = null;
  },
};
