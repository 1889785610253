import Vue from "vue";
import Child from "./Child.vue";
import VAddBtn from "./VAddBtn.vue";

import VImgExtend from "./VImgExtend.vue";
import VTextFieldExtend from "./VTextFieldExtend.vue";
import VListExtend from "./VListExtend.vue";

[Child, VAddBtn, VImgExtend, VTextFieldExtend, VListExtend].forEach((Component) => {
  Vue.component(Component.name, Component);
});
