import axios from "axios";

import { DefaultCategoryModel } from "@/@types/model";

import { API_URL } from "../config";

const getDefault = async (): Promise<DefaultCategoryModel[]> => {
  const { data, status } = await axios.get(`${API_URL}/categories/default`);
  return status === 200 && data ? data : [];
};

export default { getDefault };
