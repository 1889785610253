import { ActionTree } from "vuex";
import i18n from "@/plugins/i18n";

import { LocalizerState, types } from "./types";
import { RootState } from "../types";

import menusService from "../../services/menus.service";
import suppliersService from "../../services/suppliers.service";
import { CategoryModel } from "@/@types/model";

export const actions: ActionTree<LocalizerState, RootState> = {
  async fetchMenu({ commit, dispatch, state }, menuId) {
    commit(types.REQUESTED_MENU);
    try {
      const menu = await menusService.getById(menuId);

      await dispatch("fetchMenuSettings");

      if (!state.menuSettings) return;

      commit(types.REQUESTED_MENU_SUCCEEDED, menu);
    } catch (error) {
      commit(types.REQUESTED_MENU_FAILED, error);
    }
  },

  async fetchMenuSettings({ commit, rootState }) {
    const { currentSupplier } = rootState.auth.user;
    if (!currentSupplier) return;

    commit(types.REQUESTED_MENU_SETTINGS);
    try {
      const supplierId = currentSupplier.id;
      const settings = await suppliersService.getFieldsById(supplierId, [
        "defaultLanguage",
        "alternativeLanguages",
      ]);
      if (!settings) throw Error(`${i18n.t("InternalError.SupplierSettingsNotFound")}`);

      commit(types.REQUESTED_MENU_SETTINGS_SUCCEEDED, {
        defaultLanguage: settings.defaultLanguage,
        alternativeLanguages: settings.alternativeLanguages,
      });
    } catch (error) {
      commit(types.REQUESTED_MENU_SETTINGS_FAILED, error);
    }
  },

  async saveMenu({ commit, state }) {
    if (!state.menu) return;

    commit(types.REQUESTED_SAVE_MENU);
    try {
      const menuId = state.menu.id;
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { id, userId, supplierId, createdAt, updatedAt, deletedAt, ...menu } = state.menu;

      await menusService.updateById(menuId, menu);

      commit(types.REQUESTED_SAVE_MENU_SUCCEEDED);
    } catch (error) {
      console.log(error);
      commit(types.REQUESTED_SAVE_MENU_FAILED, error);
    }
  },

  discardMenu({ commit }) {
    commit(types.DISCARD_MENU);
  },

  setSelectedMenu({ commit }) {
    commit(types.SET_SELECTED_MENU);
  },

  setSelectedCategory({ commit }, categoryId) {
    commit(types.SET_SELECTED_CATEGORY, categoryId);
  },

  setSelectedDish({ commit }, { categoryId, dishId }) {
    commit(types.SET_SELECTED_DISH, { categoryId, dishId });
  },

  clearLocalizer({ commit }) {
    commit(types.CLEAR_LOCALIZER);
  },
};
