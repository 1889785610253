import {Component} from "vue-property-decorator";
import { Get, Sync, Call } from "vuex-pathify";
import { v4 as uuid } from "uuid";

import Resend from "./resend";
import { mixins } from "vue-class-component";
import { NotificationModel } from "@/store/notifications/types";

@Component
export default class Notifications extends mixins(Resend) {
  @Sync('notifications/notifications') notifications!: NotificationModel[]
  @Get('app/settings@isFirstConfig') isFirstConfig!: boolean
  @Get('auth/user@emailVerifiedAt') emailVerifiedAt!: string | null;
  @Get('auth/user@email') email!: string;

  @Call('notifications/add') add!: (_notification: NotificationModel) => Promise<void>;
  @Call('notifications/clear') clear!: () => Promise<void>;

  created() {
    if (this.isFirstConfig) {
      this.add({
        id: uuid(),
        icon: "mdi-exclamation",
        type: "warning",
        text: "Fill in application main settings",
        date: new Date(),
        actions: [
          {
            key: "settings",
            text: "Go to main settings",
            handler: () => this.$router.push({ name: "settings.first" })
          },
        ],
        isDashboard: true,
        isNotify: true,
      });
    }

    if (!this.emailVerifiedAt) {
      this.add({
        id: uuid(),
        icon: "mdi-information",
        type: "info",
        text: "Auth.WeSentYouVerificationLinkSent",
        date: new Date(),
        actions: [
          {
            key: "resend",
            text: "Auth.ResendVerLink",
            handler: this.handleClickResend,
          },
        ],
        isDashboard: true,
        isNotify: true,
      });
    }
  }

  destroyed() {
    this.clear();
  }

  async handleClickResend() {
    await this.resend(this.email);
    if (this.errorCode) return;

    this.$store.set("notifications/notify", {
      id: uuid(),
      manager: "notify",
      text: this.$t("Auth.VerificationLinkSentExtend", { email: this.email }),
      type: "success",
      timeout: -1,
    });
  }
}
