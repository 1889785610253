import axios from "axios";

import catchErrors from "../helpers/catchErrors";
import * as qs from "../helpers/queryString";

import { API_URL } from "../config";

const getAll = catchErrors(async (options = { query: null }) => {
  const { query } = options;
  const queryString = qs.stringify(query);

  const { data, status } = await axios.get(`${API_URL}/orders${queryString}`);
  return status === 200 && data ? data : {};
});

const updateById = catchErrors(async (orderId, order) => {
  const { status } = await axios.patch(`${API_URL}/orders/${orderId}`, order);
  return status === 200;
});

const updateByIds = catchErrors(async (orderIds, data) => {
  const { status } = await axios.patch(`${API_URL}/orders/update`, {
    orders: orderIds.map((orderId) => ({ orderId, data })),
  });
  return status === 200;
});

const deleteById = catchErrors(async (orderId) => {
  const { status } = await axios.delete(`${API_URL}/orders/${orderId}`);
  return status === 201;
});

const deleteByIds = catchErrors(async (orderIds) => {
  const { status } = await axios.post(`${API_URL}/orders/delete`, { orderIds });
  return status === 200;
});

export default { getAll, updateById, updateByIds, deleteById, deleteByIds };
