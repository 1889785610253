import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { MenuState } from "./types";

import { RootState } from "../types";
import { MenuModel } from "@/@types/model";

export const MenuSchema = (): MenuModel => ({
  id: null,
  userId: null,
  supplierId: null,

  isPublished: false,
  isEnabledToOrder: false,

  title: {},
  internalComment: {},
  photo: null,
  categories: [],

  priceCurrency: null,

  isPriceVisible: true,
  isEnergyVisible: true,

  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  archivedAt: null,
});

// state
export const state: MenuState = {
  cacheMenu: null,
  menu: MenuSchema(),
  menuSettings: null,
  selectedCategoryId: null,

  isMenuLoading: true,
  isMenuSettingsLoading: false,
  isMenuBusy: false,
  menuError: null,

  menus: [],
  isMenusLoading: true,
  menusError: null,
};

const namespaced: boolean = true;

export const menu: Module<MenuState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
