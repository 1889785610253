<template>
  <v-card class="qrcode-scanned-card" elevation="2">
    <v-toolbar class="qrcode-scanned-card__toolbar" flat>
      <v-toolbar-title> {{ $t("Dashboard.TotalQRCodeViewsCardHeader") }} </v-toolbar-title>
      <v-spacer />
      <div :style="{ whiteSpace: 'nowrap' }" class="orange--text">
        {{ $t("GlobalUI.ComingSoon") }}
      </div>
    </v-toolbar>

    <v-divider />

    <div class="qrcode-scanned-card__body">
      <template v-if="!isLoading">
        <div class="qrcode-scanned">
          <span class="qrcode-scanned__views">7,123</span>
        </div>
        <apexchart type="bar" height="310" :options="options" :series="series"></apexchart>
      </template>
      <div
        v-else
        class="d-flex justify-center align-center"
        :style="{ height: '100%', minHeight: '310px' }"
      >
        <v-progress-circular color="primary" indeterminate />
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

import { fakeRequest } from "../../services";
import { mapGetters } from "vuex";

export default {
  name: "VQrCodeScannedCard",

  components: { apexchart: VueApexCharts },

  data() {
    return {
      series: [
        {
          name: "Desktops",
          data: [53, 102, 178, 248, 472],
        },
      ],

      isLoading: true,
    };
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
    options() {
      const m = moment().locale(this.locale);
      const months = [];

      for (let i = 0; i < 5; i++) months.push(m.add(1, "month").format("MMM"));

      return {
        chart: {
          background: "transparent",
          toolbar: {
            show: false,
          },
        },
        colors: ["#009688"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          categories: months,
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
      };
    },
  },

  async created() {
    await fakeRequest(2500);
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.qrcode-scanned-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  &__body {
    flex-grow: 1;
  }
}

.qrcode-scanned {
  position: relative;
  padding: 15px;
  padding-bottom: 0;
  &__views {
    position: absolute;
    font-size: 32px;
    line-height: 1;
    font-weight: 400;
  }
}
</style>