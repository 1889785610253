import { make } from "vuex-pathify";

import { MutationTree } from "vuex";
import { state } from "./state";

import { MediaState } from "./types";

export const mutations: MutationTree<MediaState> = {
  ...make.mutations(state),

  CLEAR_MEDIA(state) {
    state.mediaList = [];
    state.isLoading = false;
    state.selectMedia = null;
    state.isBusy = false;
  },
};
