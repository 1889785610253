var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-preview"},[_c('v-btn',{staticClass:"preview-drawer__close",attrs:{"x-large":"","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"menu-preview__toggle"},[_c('v-btn',{attrs:{"color":_vm.deviceType === 'mobile' ? 'primary' : 'default'},on:{"click":function($event){_vm.deviceType = 'mobile'}}},[_vm._v(" Mobile ")]),_c('v-btn',{attrs:{"color":"default","fab":""},on:{"click":function($event){_vm.$refs.iframe.src = '';
        _vm.$refs.iframe.src = _vm.localUrl;}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"color":_vm.deviceType === 'tablet' ? 'primary' : 'default'},on:{"click":function($event){_vm.deviceType = 'tablet'}}},[_vm._v(" Tablet ")])],1),_c('div',{staticClass:"preview-device",class:{
      'preview-device--mobile': _vm.deviceType === 'mobile',
      'preview-device--tablet': _vm.deviceType === 'tablet',
    }},[_c('div',{staticClass:"black portrait device",class:{
        iphone: _vm.deviceType === 'mobile',
        ipad: _vm.deviceType === 'tablet',
      }},[_c('div',{staticClass:"caseBorder"}),_c('div',{staticClass:"case"}),_c('div',{staticClass:"reflection"}),_c('div',{staticClass:"screen"}),_c('div',{staticClass:"camera"}),_c('div',{staticClass:"speaker"}),_c('div',{staticClass:"homeButtonBorder"}),_c('div',{staticClass:"homeButton"}),_c('div',{staticClass:"content centerVH"},[_c('iframe',{ref:"iframe",attrs:{"src":_vm.localUrl,"seamless":"","title":"Preview"}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }