import moment from "moment";
import { ActionTree } from "vuex";
import { cloneDeep, pick } from "lodash";
import i18n from "@/plugins/i18n";

import { MenuState, types } from "./types";
import { RootState } from "../types";

import menusService from "../../services/menus.service";
import suppliersService from "../../services/suppliers.service";

import { MenuModel, SupplierModel } from "@/@types/model";
import { Dictionary } from "@/@types/common";

import { DEFAULT_MENU_NAMES } from "@/config";

export const actions: ActionTree<MenuState, RootState> = {
  async saveMenu({ commit, state }, data: Partial<MenuModel> = {}) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { id, createdAt, updatedAt, ...menu } = state.menu;

    const title = Object.keys(menu.title).reduce((carry: Dictionary<string>, code) => {
      carry[code] = menu.title[code] || DEFAULT_MENU_NAMES[code] || 'My Menu'
      return carry
    }, {});

    commit(types.REQUESTED_SAVE_MENU);
    const prepareMenu = { ...menu, ...data, title };

    try {
      if (!id) {
        const { menuId } = await menusService.save(prepareMenu);
        prepareMenu.id = menuId;

        window.history.pushState({}, "", `/menus/${menuId}/edit`);
        commit(types.REQUESTED_SAVE_MENU_SUCCEEDED, prepareMenu);
      } else {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const { userId, supplierId, ...prepareMenuForUpdate } = prepareMenu;
        await menusService.updateById(id, prepareMenuForUpdate);
        commit(types.REQUESTED_SAVE_MENU_SUCCEEDED, prepareMenuForUpdate);
      }
    } catch (error) {
      commit(types.REQUESTED_SAVE_MENU_FAILED, error);
    }
  },

  async fetchMenu({ commit, state, dispatch }, menuId) {
    commit(types.REQUESTED_MENU);

    try {
      const responseMenu = await menusService.getById(menuId);
      const menu = { ...state.menu, ...responseMenu };

      const firstCategory = menu.categories[0];

      await dispatch("fetchMenuSettings");
      await dispatch(
        "categories/fetchCategories",
        {
          menuSettings: state.menuSettings,
          menuCategories: menu.categories,
        },
        {
          root: true,
        },
      );

      commit(types.REQUESTED_MENU_SUCCEEDED, { menu, firstCategory });
    } catch (error) {
      commit(types.REQUESTED_MENU_FAILED, error);
    }
  },

  async fetchDefaultMenu({ commit, dispatch, state, rootState }) {
    const { currentSupplier } = rootState.auth.user;
    if (!currentSupplier) return;
    
    commit(types.REQUESTED_MENU);

    const userId = rootState.auth.user.id;
    const supplierId = currentSupplier.id;

    try {
      const responseMenu = await menusService.getDefault();

      await dispatch("fetchMenuSettings"); 
      
      if (!state.menuSettings) return;

      const { defaultCurrency, defaultLanguage, alternativeLanguages } = state.menuSettings;
      const languages = [defaultLanguage, ...alternativeLanguages];

      const menu = {
        ...state.menu,
        ...responseMenu,

        title: menusService.generateLanguageObject(languages, "string"),
        internalComment: menusService.generateLanguageObject(languages, "string"),

        userId,
        supplierId,
        priceCurrency: defaultCurrency || null,
      };

      commit(types.REQUESTED_MENU_SUCCEEDED, { menu });

      await dispatch(
        "categories/fetchCategories",
        {
          menuSettings: state.menuSettings,
          menuCategories: menu.categories,
        },
        { root: true },
      );
    } catch (error) {
      commit(types.REQUESTED_MENU_FAILED, error);
    }
  },

  async fetchMenus({ commit }) {
    commit(types.REQUESTED_MENUS);
    try {
      const menusResponse: MenuModel[] = await menusService.getAll();

      const menus = menusResponse
        .filter((menu) => !menu.archivedAt && !menu.deletedAt)
        .map((menu) => pick(menu, ["id", "title"]));

      commit(types.REQUESTED_MENUS_SUCCEEDED, menus);
    } catch (error) {
      commit(types.REQUESTED_MENUS_FAILED, error);
    }
  },

  async fetchMenuSettings({ commit, rootState }) {
    const { currentSupplier } = rootState.auth.user;
    if (!currentSupplier) return;

    commit(types.REQUESTED_MENU_SETTINGS);
    try {
      const supplierId = currentSupplier.id;
      const settings = await suppliersService.getFieldsById(supplierId, [
        "defaultCurrency",
        "defaultLanguage",
        "alternativeLanguages",
        "vatRates",
      ]) as SupplierModel;
      if (!settings) throw Error(`${i18n.t('InternalError.SupplierSettingsNotFound')}`);

      commit(types.REQUESTED_MENU_SETTINGS_SUCCEEDED, {
        defaultCurrency: settings.defaultCurrency.sign,
        defaultLanguage: settings.defaultLanguage,
        alternativeLanguages: settings.alternativeLanguages,
        vatRates: settings.vatRates,
      });
    } catch (error) {
      commit(types.REQUESTED_MENU_SETTINGS_FAILED, error);
    }
  },

  async archiveMenu({ commit, state: { menu } }) {
    const archivedAt = moment().format();
    const payload = {
      archivedAt,
      isPublished: false,
      isEnabledToOrder: false,
    };
    await menusService.updateById(menu.id, payload);
    commit(types.UPDATE_MENU, payload);
  },

  async unArchiveMenu({ commit, state: { menu } }) {
    await menusService.updateById(menu.id, { archivedAt: null });
    commit(types.UPDATE_MENU, { archivedAt: null });
  },

  updateMenu({ commit }, payload: Partial<MenuModel>) {
    commit(types.UPDATE_MENU, payload);
  },

  setSelectedCategoryId({ commit }, payload) {
    commit(types.SET_SELECTED_CATEGORY, payload);
  },

  clearSelectedCategoryId({ commit }) {
    commit(types.CLEAR_SELECTED_CATEGORY);
  },

  setCategories({ commit }, payload) {
    commit(types.SET_MENU_FIELD, { field: "categories", payload });
  },

  addCategory({ commit }, payload) {
    commit(types.ADD_CATEGORY, payload);
  },
  updateCategory({ commit }, { categoryId, data }) {
    commit(types.UPDATE_CATEGORY, { categoryId, data });
  },
  deleteCategory({ commit }, payload) {
    commit(types.DELETE_CATEGORY, payload);
  },

  addDish({ commit }, { categoryId, dish }) {
    commit(types.ADD_DISH, { categoryId, dish });
  },
  updateDish({ commit }, { categoryId, dishId, dish }) {
    commit(types.UPDATE_DISH, { categoryId, dishId, dish });
  },
  deleteDish({ commit }, { categoryId, dishId }) {
    commit(types.DELETE_DISH, { categoryId, dishId });
  },

  setDishes({ commit }, { categoryId, dishes }) {
    commit(types.SET_DISHES, { categoryId, dishes });
  },

  discardMenu({ commit, state }, fields?: Array<keyof MenuModel>) {
    if (!state.cacheMenu) return;

    const localFields: Array<keyof MenuModel> = [];
    if (typeof fields === "string") localFields.push(fields);
    else if (Array.isArray(fields)) localFields.push(...fields);

    let menuFields: Partial<MenuModel> = {};

    if (localFields.length) {
      menuFields = localFields.reduce((acc: { [key: string]: MenuModel[keyof MenuModel]}, field) => {
        if (state.cacheMenu && state.cacheMenu[field]) {
          acc[field] = cloneDeep(state.cacheMenu[field]);
        }
        return acc;
      }, {});
    } else {
      menuFields = cloneDeep(state.cacheMenu);
    }

    commit(types.UPDATE_MENU, menuFields);
  },

  clearMenu({ commit }) {
    commit(types.CLEAR_MENU);
  },

  clearMenus({ commit }) {
    commit(types.CLEAR_MENUS);
  },

  clearAll({ commit }) {
    commit(types.CLEAR_MENU);
    commit(types.CLEAR_MENUS);
  },
};
