import Vue from "vue";
import { AbilityBuilder, Ability } from "@casl/ability";
import { Can, abilitiesPlugin } from "@casl/vue";

import { UserModel } from "@/@types/model";

export const ability = new Ability();

export function defineAbilitiesFor(user: UserModel) {
  const { can, rules } = new AbilityBuilder(Ability);

  const userRolePermissions = user.role?.permissions.map((permission) => permission.token) || [];
  const userPermissions = user.permissions.map((permission) => permission.token);
  const permissions = [...userRolePermissions, ...userPermissions].map((permission) => {
    const tokens = permission.split(":");
    if (tokens.length === 3) return { type: tokens[0], subject: tokens[1], action: tokens[2] };
    if (tokens.length === 2) return { type: tokens[0], subject: tokens[1], action: "*" };
    return { type: tokens[0], subject: null, rule: "*" };
  });

  permissions.forEach((permission) => {
    const { type, subject, action } = permission;
    if (type && subject && action) can(action, `${type}:${subject}`);
    if (type && !subject && action) can(action, `${type}`);
  });

  return rules;
}

Vue.use(abilitiesPlugin, ability);
Vue.component("Can", Can);

Vue.prototype.$mcan = (actions: string[], subject: string) =>
  actions.some((action) => ability.can(action, subject));
