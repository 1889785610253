import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { API_URL } from "../config";

@Component
export default class Resend extends Vue {
  errorCode: string | null = null;

  isVerified: boolean = false;
  isAlreadyVerified: boolean = false;

  isVerifyBusy: boolean = false;

  async resend(email: string) {
    this.isVerifyBusy = true;
    try {
      const { data } = await axios.post(`${API_URL}/email/resend`, { email });

      this.isVerified = data.isVerified;
      this.isAlreadyVerified = data.isAlreadyVerified;

      if (this.isVerified && this.isAlreadyVerified) this.errorCode = "ALREADY_VERIFIED";
    } catch ({ response }) {
      const { data } = response;
      this.errorCode = data.code || null;
    } finally {
      this.isVerifyBusy = false;
    }
  }
}
