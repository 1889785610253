import { TranslateResult } from "vue-i18n";
import {
  DialogAction,
  DialogActions,
  DialogConfirmOptions,
  DialogNotifyOptions,
} from "vuetify-dialog";
import i18n from "../plugins/i18n";

export const defaultConfirmActions = (): DialogActions => ({
  false: {
    key: "",
    text: String(i18n.t("GlobalUI.Cancel")),
    flat: false,
  },
  true: {
    key: "",
    color: "primary",
    text: String(i18n.t("GlobalUI.Yes")),
    flat: false,
  },
});

export const confirm = (
  title?: string | TranslateResult,
  text?: string | TranslateResult,
  actions?: Array<string | DialogAction> | Array<DialogAction> | DialogActions,
): DialogConfirmOptions => ({
  title: String(title ?? i18n.t("Messages.Warning")),
  text: String(text ?? i18n.t("Messages.AreYouSureToContinue")),
  actions: actions ?? defaultConfirmActions(),
  showClose: false,
});

const defaultToastOptions = (): DialogNotifyOptions => ({
  position: "top-right",
  timeout: 2000,
});

export const toast = (
  text: string = "Toast",
  options?: DialogNotifyOptions | undefined,
): [string, DialogNotifyOptions] => [text, options ?? defaultToastOptions()];
