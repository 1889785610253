import { MutationTree } from "vuex";
import { CategoriesState, types } from "./types";

export const mutations: MutationTree<CategoriesState> = {
  [types.SET_CATEGORIES_LOADING](state, payload) {
    state.isLoading = payload;
  },

  [types.SET_CATEGORIES](state, payload) {
    state.categories = payload;
  },

  [types.ADD_CATEGORY](state, category) {
    state.categories = state.categories.concat(category);
  },

  [types.UPDATE_CATEGORY](state, { categoryId, data }) {
    state.categories = state.categories.map((item) =>
      item.id === categoryId ? { ...item, ...data } : item,
    );
  },

  [types.DELETE_CATEGORY](state, categoryId) {
    state.categories = state.categories.filter((category) => category.id !== categoryId);
  },

  [types.CLEAR_CATEGORIES](state) {
    state.categories = [];
  },
};
