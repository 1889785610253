import { Component, Vue, Watch } from 'vue-property-decorator'
import { Sync } from "vuex-pathify";

import { NotifyModel } from "@/store/notifications/types";

@Component
export default class Notify extends Vue {
  @Sync('notifications/notify')
  notify!: NotifyModel | null


  @Watch('notify')
  async onNotifyChanged(value: NotifyModel, oldValue: NotifyModel | undefined) {
    if (!value || !value.id) return;
    if (oldValue && oldValue.id === value.id) return;

    const actions = (value.actions || []).map((action, i) => ({
      ...action,
      key: `${i}`,
      text: `${this.$t(action.text)}`,
    }));

    const manager = value.manager || "message";

    switch (manager) {
      case "message":
        await this.$dialog[manager][value.type || "warning"](`${this.$t(value.text)}`, {
          position: value.position || "top",
          timeout: value.timeout || 3000,
          actions: [...actions, { key: 'close', icon: "mdi-close", text: '' }],
        });
        this.notify = null;
        break;
      case "notify":
        await this.$dialog[manager][value.type || "warning"](`${this.$t(value.text)}`, {
          position: value.position || "top-right",
          timeout: value.timeout || 5000,
          actions,
        });
        this.notify = null;
        break;
      default:
        console.log("Dialog not found");
    }
  }
}
