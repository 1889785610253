import { ROLES } from "../config";

import Dashboard from "../pages/Dashboard.vue";

function page(path) {
  return () => import(/* webpackChunkName: '' */ `../pages/${path}`).then((m) => m.default || m);
}

export default [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { key: "GlobalUI.Dashboard" },
  },

  { path: "/login", name: "login", component: page("auth/Login"), props: true },
  {
    path: "/register",
    name: "register",
    component: page("auth/Register"),
  },

  {
    path: "/password/reset",
    name: "password.request",
    component: page("auth/password/Email"),
  },
  {
    path: "/password/reset/:token",
    name: "password.reset",
    component: page("auth/password/Reset"),
  },

  {
    path: "/email/verify/:token",
    name: "verification.verify",
    component: page("auth/verification/Verify"),
  },

  {
    path: "/profile",
    name: "profile",
    component: page("profile/Profile"),
  },

  // Menus
  {
    path: "/menus",
    component: page("menu"),
    children: [
      {
        path: "",
        name: "menus",
        component: page("menu/MenusList"),
        meta: { key: "Menu.MyMenus" },
      },
      {
        path: "archive",
        name: "menus.archive",
        component: page("menu/MenuArchiveList"),
        meta: { key: "Menu.ArchivedMenus" },
      },
      {
        path: "trash",
        name: "menus.trash",
        component: page("menu/MenuTrashList"),
        meta: { key: "Menu.TrashMenus" },
      },
      {
        path: ":menuId/edit",
        name: "menus.edit",
        component: page("menu/MenuEdit"),
        meta: { key: "Menu.EditMenu" },
      },
      {
        path: ":menuId/localizer",
        name: "menus.localizer",
        component: page("menu/MenuLocalizer"),
        meta: { key: "Menu.MenuLocalizer" },
      },
      {
        path: "create",
        name: "menus.create",
        component: page("menu/create/MenuCreate"),
        meta: { key: "Menu.MenuCreate" },
      },
      {
        path: "create/first",
        name: "menus.create.first",
        meta: { key: "Menu.MenuCreate", layout: "empty" },
        component: page("menu/create/MenuCreateFirst"),
      },
    ],
  },

  // Users
  {
    path: "/users",
    component: page("users"),
    children: [
      {
        path: "",
        name: "users",
        component: page("users/UsersList"),
        meta: { key: "Users.Users", roles: [ROLES.OWNER] },
      },
      {
        path: "create",
        name: "user.create",
        component: page("users/UserCreate"),
        meta: { key: "Users.CreateUser", roles: [ROLES.OWNER] },
      },
      {
        path: ":userId/edit",
        name: "user.edit",
        component: page("users/UserEdit"),
        meta: { key: "Users.EditUser", roles: [ROLES.OWNER] },
      },
      {
        path: ":userId/privileges",
        name: "user.privileges",
        component: page("users/UserPrivileges"),
        meta: { key: "Users.UserPermissionEditor", supInfo: "alpha", roles: [ROLES.OWNER] },
      },
    ],
  },

  {
    path: "/orders",
    component: page("orders"),
    children: [
      {
        path: "",
        name: "orders",
        component: page("orders/OrdersList"),
        meta: { key: "Orders.Orders" },
      },
    ],
  },

  {
    path: "/qrcodes",
    component: page("qrcodes"),
    children: [
      {
        path: "",
        name: "qrcodes",
        component: page("qrcodes/QRCodesList"),
        meta: { key: "QRCodes.QRCodes", roles: [ROLES.OWNER, ROLES.MANAGER] },
      },
      {
        path: "types",
        name: "qrcodes.types",
        component: page("qrcodes/QRCodesTypes"),
        meta: { key: "QRCodes.QRCodesTypes", roles: [ROLES.OWNER, ROLES.MANAGER] },
      },
      {
        path: "create",
        name: "qrcode.create",
        component: page("qrcodes/QRCodeCreate"),
        meta: { key: "QRCodes.QRCodeCreate", roles: [ROLES.OWNER, ROLES.MANAGER] },
      },
      {
        path: ":qrcodeId/edit",
        name: "qrcode.edit",
        component: page("qrcodes/QRCodeEdit"),
        meta: { key: "QRCodes.QRCodeEdit", roles: [ROLES.OWNER, ROLES.MANAGER] },
      },
      {
        path: ":qrcodeId/statistics",
        name: "qrcode.statistics",
        component: page("qrcodes/QRCodeStatistics"),
        meta: {
          key: "QRCodes.QRCodeStatistics",
          supInfo: "alpha",
          roles: [ROLES.OWNER, ROLES.MANAGER],
        },
      },
    ],
  },

  {
    path: "/settings",
    component: page("settings"),
    children: [
      {
        path: "",
        name: "settings",
        component: page("settings/Settings"),
        meta: { key: "Settings.Settings" },
      },
      {
        path: "venue",
        name: "settings.venue",
        component: page("settings/VenueSettings"),
        meta: { key: "Settings.VenueSettings" },
      },
      {
        path: "first",
        name: "settings.first",
        meta: { key: "Settings.Settings", layout: "empty" },
        component: page("settings/FirstSettings"),
      },
    ],
  },

  {
    path: "/legal",
    component: page("legal"),
    children: [
      {
        path: "",
        name: "legal",
        component: page("legal/Legal"),
        meta: { key: "Legal.Legal" },
      },
      {
        path: "terms-and-conditions",
        name: "legal.terms-and-conditions",
        component: page("legal/LegalContent"),
        meta: { key: "Legal.TermsAndConditions" },
      },
      {
        path: "cookies-policy",
        name: "legal.cookies-policy",
        component: page("legal/LegalContent"),
        meta: { key: "Legal.CookiesPolicy" },
      },
      {
        path: "privacy-policy",
        name: "legal.privacy-policy",
        component: page("legal/LegalContent"),
        meta: { key: "Legal.PrivacyPolicy" },
      },
    ],
  },

  {
    path: "/media-library",
    name: "media",
    meta: { key: "Media.Media" },
    component: page("media/Media"),
  },

  { path: "/error/500", name: "error.500", component: page("errors/InternalServer") },
  { path: "/error/404", name: "error.404", component: page("errors/NotFound") },
  { path: "*", redirect: "/error/404" },
];
