import { v4 as uuid } from "uuid";
import { ActionTree } from "vuex";
import { CategoriesState, types } from "./types";
import { RootState } from "../types";

import categoriesService from "@/services/categories.service";
import menusService from "@/services/menus.service";

import { FALLBACK_LOCALE } from "@/config";

export const actions: ActionTree<CategoriesState, RootState> = {
  async fetchCategories({ commit }, { menuSettings }) {
    commit(types.SET_CATEGORIES_LOADING, true);

    const { defaultLanguage, alternativeLanguages } = menuSettings;

    const languages = [defaultLanguage, ...alternativeLanguages];

    const categoriesResponse = await categoriesService.getDefault();
    const categories = categoriesResponse.map((category) => {
      const title = menusService.mergeLanguageObjects(languages, category.title, "string");
      if (!title[defaultLanguage]) title[defaultLanguage] = category.title[FALLBACK_LOCALE] || "";
      return {
        id: uuid(),
        title,
        photo: category.photo,
        visibility: true,
        dishes: [],
      };
    });
    commit(types.SET_CATEGORIES, categories);

    commit(types.SET_CATEGORIES_LOADING, false);
  },

  addCategory({ commit }, payload) {
    commit(types.ADD_CATEGORY, payload);
  },

  updateCategory({ commit }, payload) {
    commit(types.UPDATE_CATEGORY, payload);
  },

  deleteCategory({ commit }, payload) {
    commit(types.DELETE_CATEGORY, payload);
  },

  clearCategories({ commit }) {
    commit(types.CLEAR_CATEGORIES);
  },
};
