import store from "../store";

import { API_URL } from "../config";
import PREVIEW_IMAGE from "../assets/qr-menu-preview.jpg";
import PREVIEW_IMAGE_SMALL from "../assets/qr-menu-preview-small.jpg";

const loadImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => {
      resolve(image);
    });
    image.addEventListener("error", (error) => {
      reject(error);
    });
    image.src = url;
  });

export const generateImageUrl = async (image, partPath = null, size = "sm", fallbackSize) => {
  const localFallbackSize = fallbackSize || size;
  const { id: supplierId } = store.state.auth.user.currentSupplier;

  let fallbackImage = PREVIEW_IMAGE;
  if (localFallbackSize === "sm") fallbackImage = PREVIEW_IMAGE_SMALL;

  if (!image) return fallbackImage;
  const { name, sizes, isDefault } = image;

  const localPartPath = partPath || image.defaultImageType;
  const path = `${isDefault ? localPartPath : supplierId}/`.toLowerCase();

  const imageURL = `${API_URL}/images/${path}${sizes[size]}/${name}`;

  try {
    await loadImage(imageURL);
    return imageURL;
  } catch (error) {
    return fallbackImage;
  }
};

export const imageSize = (image) => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const img = new Image();

        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };

        img.src = fileReader.result;
      };

      fileReader.readAsDataURL(image);
    } catch (e) {
      reject(e);
    }
  });
};
