import Vue from "vue";
import VueSocketIO from "vue-socket.io";
import { io } from "socket.io-client";

import store from "../store";

import { API_DOMAIN_URL } from "../config";

const connection = io(API_DOMAIN_URL, {
  autoConnect: false,
  transports: ["websocket"],
});

connection.on("connect", () => {
  const { id, currentSupplier } = store.getters["auth/user"];

  Vue.prototype.$socket.emit("join", { userId: id, supplierId: currentSupplier.id });
});

connection.on("disconnect", () => {});

Vue.use(
  new VueSocketIO({
    debug: false,
    connection,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
    },
  }),
);
