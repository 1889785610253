<template>
  <v-card class="qrcode-card" elevation="2">
    <v-toolbar class="qrcode-card__toolbar" flat>
      <v-toolbar-title> QR Code </v-toolbar-title>
      <v-spacer />
      <a
        :style="{ whiteSpace: 'nowrap' }"
        class="primary--text"
        :href="qrcodeText || appSettings.customerURL"
        target="_blank"
      >
        {{ $t("Menu.YourQrCode") }}
      </a>
    </v-toolbar>

    <v-divider />

    <div class="qrcode-card__body">
      <v-qrcode v-if="qrcodeText" class="qrcode" ref="qrcode" :text="qrcodeText" is-full />
      <img v-else class="qrcode" :src="follbackQRcode" />
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import VQRCode from "../qrcodes/VQRCode";

import qrcodeData from "../qrcodes/qrcodeData";

import { API_URL } from "../../config";

export default {
  name: "VQRCodeCard",

  mixins: [qrcodeData],

  components: { "v-qrcode": VQRCode },

  computed: {
    ...mapGetters({
      appSettings: "app/settings",
    }),

    qrcodeText() {
      const { linkId } = this.appSettings;
      return this.qrcodeData({ linkId });
    },

    follbackQRcode() {
      return `${API_URL}/suppliers/${this.appSettings.subdomain}/qrcode`;
    },
  },
};
</script>

<style lang="scss">
.qrcode-card {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  &__body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}

.qrcode {
  max-width: 320px;
  width: 100%;
  height: auto;
}
</style>