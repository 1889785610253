<template>
  <v-card class="feedbacks-card" elevation="2">
    <v-toolbar class="feedbacks-card__toolbar" flat>
      <v-toolbar-title> {{ $t("Dashboard.FeedbacksCardHeader") }} </v-toolbar-title>
      <v-spacer />
      <div :style="{ whiteSpace: 'nowrap' }" class="orange--text">
        {{ $t("GlobalUI.ComingSoon") }}
      </div>
    </v-toolbar>

    <v-divider />

    <div class="feedbacks-card__body">
      <apexchart v-if="!isLoading" type="donut" width="360px" :options="options" :series="series" />
      <div
        v-else
        class="d-flex justify-center align-center"
        :style="{ height: '100%', minHeight: '278px' }"
      >
        <v-progress-circular color="primary" indeterminate />
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { fakeRequest } from "../../services";

export default {
  name: "VFeedbacksCard",

  components: { apexchart: VueApexCharts },

  data() {
    return {
      series: [80, 45, 15, 5],

      isLoading: true,
    };
  },

  computed: {
    options() {
      return {
        chart: {
          background: "transparent",
        },
        labels: [
          this.$t("Dashboard.VeryHappy"),
          this.$t("Dashboard.Happy"),
          this.$t("Dashboard.NotSatisfied"),
          this.$t("Dashboard.CancelledOrders"),
        ],
        dataLabels: {
          enabled: false,
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        legend: {
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        responsive: [
          {
            breakpoint: 576,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      };
    },
  },

  async created() {
    await fakeRequest(2000);
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.feedbacks-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  &__body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}
</style>

