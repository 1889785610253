import Vue from "vue";
import { Component } from "vue-property-decorator";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import Emittery from "emittery";

import {
  capitalize,
  dateFormat,
  dateOrderFormat,
  dateHumanizeFormat,
  visibleLanguage,
  normalizePrice,
} from "./services/filters.service";

import "./plugins/gtm";
import "./plugins/ability";
import "./plugins/socket";
import "./plugins/axios";
import "./plugins/vuedl";
import "./components";

import breakpoints from "./mixins/breakpoints";
import envirements from "./mixins/envirements";

import App from "./App.vue";

Vue.config.productionTip = false;

Vue.prototype.$emitter = new Emittery();

Component.registerHooks([
  "validations",
  "metaInfo",
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

new App({
  el: "#admin-app",
  i18n,
  router,
  store,
  vuetify,
}).$mount();

Vue.filter("capitalize", capitalize);
Vue.filter("dateFormat", dateFormat);
Vue.filter("dateOrderFormat", dateOrderFormat);
Vue.filter("dateHumanizeFormat", dateHumanizeFormat);
Vue.filter("visibleLanguage", visibleLanguage);
Vue.filter("normalizePrice", normalizePrice);

Vue.mixin(breakpoints);
Vue.mixin(envirements);
