import { isEqual } from "lodash";
import { GetterTree } from "vuex";

import { MenuState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<MenuState, RootState> = {
  menu: (state) => state.menu,
  menuSettings: (state) => state.menuSettings,

  isMenuEqualCache: (state) => isEqual(state.menu, state.cacheMenu),

  categoriesVisible: (state) => state.menu.categories.filter((category) => category.visibility),
  categoryById: (state) => (id: string | number) =>
    state.menu.categories.find((category) => category.id === id) || null,

  allDishes: (state) => state.menu.categories.map((category) => category.dishes).flat(),

  selectedCategoryId: (state) => state.selectedCategoryId,

  isMenuLoading: (state) => state.isMenuLoading,
  isMenuBusy: (state) => state.isMenuBusy,

  menus: (state) => state.menus,
  isMenusLoading: (state) => state.isMenusLoading,
};
