<script>
import { VImg } from "vuetify/lib";

import { generateImageUrl } from "../helpers/images";

export default {
  name: "VImgExtend",
  extends: VImg,

  props: {
    img: {
      type: Object,
      default: () => null,
    },
    fallbackSize: String,
    partPath: String,
    size: String,
  },

  data() {
    return {
      normalisedImageSrc: "",
    };
  },

  computed: {
    normalisedSrc() {
      if (this.normalisedImageSrc) {
        return {
          src: this.normalisedImageSrc,
          srcset: this.srcset,
          lazySrc: this.lazySrc,
          aspect: Number(this.aspectRatio || 0),
        };
      } else if (this.src && typeof this.src === "object") {
        return {
          src: this.src.src,
          srcset: this.srcset || this.src.srcset,
          lazySrc: this.lazySrc || this.src.lazySrc,
          aspect: Number(this.aspectRatio || this.src.aspect),
        };
      } else {
        return {
          src: this.src,
          srcset: this.srcset,
          lazySrc: this.lazySrc,
          aspect: Number(this.aspectRatio || 0),
        };
      }
    },
  },

  watch: {
    async img() {
      this.normalisedImageSrc = await this.getImageUrl();
      this.loadImage();
    },
  },

  async created() {
    this.normalisedImageSrc = await this.getImageUrl();
    this.loadImage();
  },

  methods: {
    async getImageUrl() {
      return generateImageUrl(this.img, this.partPath, this.size, this.fallbackSize);
    },
  },
};
</script>