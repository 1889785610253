import { NetStatus } from "@/@types/common";
import { AppSettingsModel } from "@/@types/model";

export interface Sounds {
  order: boolean;
}

export interface AppState {
  version: string;
  netStatus: NetStatus;

  theme: string | null;
  sounds: Sounds;
  settings: AppSettingsModel;

  isSettingsLoading: boolean;
  settingsError: string | null;
}

export enum types {
  REQUESTED_SETTINGS = "APP/REQUESTED_SETTINGS",
  REQUESTED_SETTINGS_SUCCEEDED = "APP/REQUESTED_SETTINGS_SUCCEEDED",
  REQUESTED_SETTINGS_FAILED = "APP/REQUESTED_SETTINGS_FAILED",
  UPDATE_SETTINGS = "APP/UPDATE_SETTINGS",
}
