

































import { MenuModel } from "@/@types/model";
import { Component, Watch, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import { menuEvents } from "@/constants/events";

@Component({ name: "VAppMenuToolbar" })
export default class VAppMenuToolbar extends Vue {
  selectedMenuId: string | number | null = null;

  @Get("app/defaultLanguage")
  defaultLanguage!: string;

  @Get("menu/menu")
  menu!: MenuModel;

  @Get("menu/menus")
  menus!: MenuModel[];

  @Get("menu/isMenuEqualCache")
  isMenuEqualCache!: boolean;

  @Get("menu/isMenuLoading")
  isMenuLoading!: boolean;

  @Get("menu/isMenuBusy")
  isMenuBusy!: boolean;

  @Get("menu/isMenusLoading")
  isMenusLoading!: boolean;

  @Watch("menu")
  onMenuChanged(menu: MenuModel) {
    if (!menu.id) return;
    this.selectedMenuId = menu.id;
  }

  handleChangeMenu(menuId: string | number) {
    this.$router.push({ name: "menus.edit", params: { menuId: String(menuId) } });
  }

  handleShowMenuSchedule() {
    this.$emitter.emit(menuEvents.SHOW_MENU_SCHEDULE);
  }

  handleShowMenuSettings() {
    this.$emitter.emit(menuEvents.SHOW_MENU_SETTINGS);
  }
}
