import { isEqual, cloneDeep } from "lodash";
import { GetterTree } from "vuex";

import { LocalizerState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<LocalizerState, RootState> = {
  isLoading: (state) => state.isLoading,
  isMenuBusy: (state) => state.isMenuBusy,

  menu: (state) => state.menu,
  isMenuEqualCache: (state) => isEqual(state.menu, state.cacheMenu),

  menuSettings: (state) => state.menuSettings,

  defaultLanguage: (state) => state.menuSettings?.defaultLanguage,
  alternativeLanguages: (state) => state.menuSettings?.alternativeLanguages,

  selectedCategory: (state) => {
    const { selectedCategory } = state;
    if (!state.menu || !selectedCategory) return null;
    return cloneDeep(state.menu.categories.find((category) => category.id === selectedCategory));
  },
  selectedDish: (state) => {
    const { selectedCategory, selectedDish } = state;
    if (!state.menu || !selectedCategory || !selectedDish) return null;
    return cloneDeep(
      state.menu.categories
        .find((category) => category.id === selectedCategory)
        ?.dishes.find((dish) => dish.id === selectedDish),
    );
  },

  selectType: (state) => state.selectType,

  translateLanguage: (state) => state.translateLanguage,
};
