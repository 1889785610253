import { cloneDeep } from "lodash";
import { MutationTree } from "vuex";
import { make } from "vuex-pathify";

import { state } from "./state";

import { LocalizerState, types } from "./types";

export const mutations: MutationTree<LocalizerState> = {
  ...make.mutations(state),

  /* Fetch menu */
  [types.REQUESTED_MENU](state) {
    state.isLoading = true;
  },

  [types.REQUESTED_MENU_SUCCEEDED](state, menu) {
    state.menu = menu;
    state.cacheMenu = cloneDeep(menu);
    state.isLoading = false;
  },

  [types.REQUESTED_MENU_FAILED](state) {
    state.menu = null;
    state.isLoading = false;
  },

  /* Fetch menu settings */
  [types.REQUESTED_MENU_SETTINGS](state) {
    state.isMenuSettingsLoading = true;
  },

  [types.REQUESTED_MENU_SETTINGS_SUCCEEDED](state, menuSettings) {
    state.menuSettings = menuSettings;
    state.isMenuSettingsLoading = false;
  },

  [types.REQUESTED_MENU_SETTINGS_FAILED](state) {
    state.menuSettings = null;
    state.isMenuSettingsLoading = false;
  },

  /* Save menu */
  [types.REQUESTED_SAVE_MENU](state) {
    state.isMenuBusy = true;
  },

  [types.REQUESTED_SAVE_MENU_SUCCEEDED](state) {
    state.cacheMenu = cloneDeep(state.menu);
    state.isMenuBusy = false;
  },

  [types.REQUESTED_SAVE_MENU_FAILED](state) {
    state.isMenuBusy = false;
  },

  [types.DISCARD_MENU](state) {
    state.menu = cloneDeep(state.cacheMenu);
  },

  [types.SET_SELECTED_MENU](state) {
    state.selectedCategory = null;
    state.selectedDish = null;
    state.selectType = "MENU";
  },

  [types.SET_SELECTED_CATEGORY](state, categoryId) {
    state.selectedCategory = categoryId;
    state.selectedDish = null;
    state.selectType = "CATEGORY";
  },

  [types.SET_SELECTED_DISH](state, { categoryId, dishId }) {
    state.selectedCategory = categoryId;
    state.selectedDish = dishId;
    state.selectType = "DISH";
  },

  [types.CLEAR_LOCALIZER](state) {
    state.cacheMenu = null;
    state.menu = null;

    state.selectedCategory = null;
    state.selectedDish = null;

    state.selectType = null;
  },
};
