export const defaultValueByType = (type: string) => {
  if (typeof type !== "string") throw new TypeError("Type must be a string.");

  switch (type) {
    case "boolean":
      return false;
    case "function":
      return function () {};
    case "null":
      return null;
    case "number":
      return 0;
    case "object":
      return {};
    case "array":
      return [];
    case "string":
      return "";
    case "symbol":
      return Symbol();
    case "undefined":
      return void 0;
  }
};
