import { CountryModel } from "@/@types/model";
import { Language } from "@/store/countries/types";
import store from "../store";

const DEFAULT_TOP_LANGUAGES_CODES = ["ru", "en", "cs", "fr", "de", "es"];

const generateLanguagesForCountry = (
  currentCountry: string,
  countries: CountryModel[] = store.get("countries@countries"),
  languages: Language[] = store.get("countries@languages"),
) => {
  const country = countries.find((c) => c.alpha3Code === currentCountry);
  if (!country) return languages;

  const countryLanguages = country.languages;
  const countryLanguagesCodes = countryLanguages
    .map((l) => l.iso639_1)
    .filter(l => l !== null) as string[];

  const topLanguagesCodes = [
    ...new Set([...countryLanguagesCodes, ...DEFAULT_TOP_LANGUAGES_CODES]),
  ];

  const topLanguages = topLanguagesCodes
    .map((code) => languages.find((language) => language.code === code))
    .filter(language => language !== undefined) as Language[]

  const otherLanguages = languages.filter((language) => !topLanguagesCodes.includes(language.code))

  const totalLanguages = [ ...topLanguages, ...otherLanguages ];

  return totalLanguages;
};

export default { generateLanguagesForCountry };
