import { CountryModel } from "@/@types/model";

export interface Language {
  code: string;
  label: string;
}

export interface CountriesState {
  countries: CountryModel[];
  languages: Language[];
}

export enum types {
  SET_COUNTRIES = "COUNTRIES/SET_COUNTRIES",
  SET_LANGUAGES = "COUNTRIES/SET_LANGUAGES",
}
