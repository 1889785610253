import { v4 as uuid } from "uuid";
import { ActionTree } from "vuex";

import store from "../index";
import { OrdersState, types } from "./types";
import { RootState } from "../types";

import ordersService from "@/services/orders.service";
import newOrderSong from "../../assets/new-order.mp3";

const newOrderAudio = new Audio();
const newOrderAudioButton = document.createElement("button");
newOrderAudioButton.onclick = () => {
  newOrderAudio.src = newOrderSong as string;
  newOrderAudio.play();
};

export const actions: ActionTree<OrdersState, RootState> = {
  async fetchOrders({ commit }, options = {}) {
    commit(types.REQUESTED_ORDERS);
    try {
      const data = await ordersService.getAll({ query: { options } });
      commit(types.REQUESTED_ORDERS_SUCCEEDED, data);
    } catch (error) {
      commit(types.REQUESTED_ORDERS_FAILED, error.response);
    }
  },

  [`SOCKET_newOrder`]({ commit }, order) {
    const route = store.get("route");

    const notify = {
      id: uuid(),
      text: `New order #${order.internalId}`,
      type: "warning",
    };

    if (route.name !== "orders") {
      const notifications = store.copy("notifications/notifications");
      store.set("notifications/notifications", [
        {
          date: order.createdAt,
          group: "orders",
          icon: "mdi-cart",
          actions: [{ to: "/orders" }],
          isSnackbar: false,
          ...notify,
        },
        ...notifications,
      ]);
    }

    const soundOrder = store.get("app/sounds.order");
    if (soundOrder) newOrderAudioButton.dispatchEvent(new Event("click"));

    store.set("notifications/notify", { ...notify, manager: "notify" });

    commit(types.ADD_ORDER, order);
  },

  clearMenus({ commit }) {
    commit(types.CLEAR_ORDERS);
  },
};
