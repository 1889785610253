










































import { Component, Vue, Watch } from "vue-property-decorator";
import { Get } from "vuex-pathify";

@Component({ name: "VNewFeatures" })
export default class VNewFeatures extends Vue {
  showFeaturesAlert: boolean = false;

  @Get("app/version")
  readonly appVersion!: string;

  @Watch("showFeaturesAlert")
  onShowFeaturesAlertChanged(value: boolean) {
    if (value !== false) return;
    localStorage.setItem("version", this.appVersion);
  }

  created() {
    const currentVersion = localStorage.getItem("version");
    if (!currentVersion) localStorage.setItem("version", this.appVersion);
    this.showFeaturesAlert = Boolean(currentVersion) && currentVersion !== this.appVersion;
  }
}
