<template>
  <v-btn
    :style="{ minWidth: size, height: size, padding: 0 }"
    :to="to"
    @click.stop.prevent="action"
    :color="color"
    :disabled="disabled"
  >
    <v-icon :size="iconSize">mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "v-add-btn",

  props: {
    size: {
      type: String,
      default: "50px",
    },
    iconSize: {
      type: String,
      default: "32px",
    },
    color: {
      type: String,
      default: "primary",
    },
    to: [String, Object],
    action: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>