import Vue from "vue";
import { ActionTree } from "vuex";
import { AuthState, types } from "./types";
import { RootState } from "../types";

import { ability, defineAbilitiesFor } from "../../plugins/ability";

import authService from "@/services/auth.service";

export const actions: ActionTree<AuthState, RootState> = {
  saveToken({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async fetchUser({ commit }) {
    try {
      const user = await authService.getCurrentUser();
      if (!user) throw new Error("User not fetched");

      commit(types.FETCH_USER_SUCCESS, { ...user, netStatus: "ONLINE" });

      Vue.prototype.$socket.open();
      ability.update(defineAbilitiesFor(user));
    } catch (error) {
      commit(types.FETCH_USER_FAILURE);
      throw error;
    }
  },

  updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  clearUser({ commit }) {
    commit(types.CLEAR_USER);
  },

  async logout({ commit, state }) {
    try {
      await authService.logout(state.user);
      Vue.prototype.$socket.close();

      commit(types.LOGOUT);
    } catch (error) {
      console.error(error);
    }
  },
};
