import { LocalizerState } from "./types";

export const state: LocalizerState = {
  isLoading: false,
  isMenuBusy: false,
  isMenuSettingsLoading: false,

  cacheMenu: null,
  menu: null,
  menuSettings: null,

  selectedCategory: null,
  selectedDish: null,

  selectType: "MENU",

  translateLanguage: null,
};
