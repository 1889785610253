import { pick } from "lodash";
import { GetterTree } from "vuex";
import { MenusState } from "./types";
import { RootState } from "../types";
import { MenuModel } from "@/@types/model";

export const getters: GetterTree<MenusState, RootState> = {
  menus: (state) => state.menus.filter((menu) => !menu.archivedAt && !menu.deletedAt),

  menusOnlyFields:
    (state) =>
    (...fields: Array<keyof MenuModel>) =>
      state.menus
        .filter((menu) => !menu.archivedAt && !menu.deletedAt)
        .map((menu) => pick(menu, fields)),

  archivedMenus: (state) => state.menus.filter((menu) => menu.archivedAt),

  deletedMenus: (state) => state.menus.filter((menu) => menu.deletedAt),

  isMenusLoading: (state) => state.isMenusLoading,
};
