const defaultOptions = { isThrow: false };

interface Options {
  isThrow?: boolean;
}
const catchErrors =
  <T = any>(fn: Function, opts: Options = {}) =>
  async (...args: any): Promise<T | void> => {
    const options = { ...defaultOptions, ...opts };
    try {
      return fn(...args);
    } catch (error) {
      console.error(error);
      if (options.isThrow) throw error;
    }
  };

export default catchErrors;
