import axios from "axios";

import { defaultValueByType } from "../helpers/types";

import catchErrors from "../helpers/catchErrors";
import * as qs from "../helpers/queryString";
import { API_URL, FALLBACK_LOCALE } from "../config";

const getDefault = async () => {
  return {
    title: {},
    categories: [],
  };
};

const getAll = catchErrors(async (options = { query: null }) => {
  const { query } = options;
  const queryString = qs.stringify(query);

  const { data, status } = await axios.get(`${API_URL}/menus${queryString}`);
  return status === 200 && data ? data : [];
});

const getById = catchErrors(async (menuId) => {
  const { data, status } = await axios.get(`${API_URL}/menus/${menuId}`);
  return status === 200 && data ? data : null;
});

const save = catchErrors(async (menu) => {
  const { data, status } = await axios.post(`${API_URL}/menus`, menu);
  return status === 200 && data ? data : null;
});

const duplicate = catchErrors(async (menu) => {
  const { data, status } = await axios.post(`${API_URL}/menus/duplicate`, menu);
  return status === 200 && data ? data : null;
});

const updateById = catchErrors(async (menuId, menu) => {
  const { status } = await axios.patch(`${API_URL}/menus/${menuId}`, menu);
  return status === 200;
});

const deleteById = catchErrors(async (menuId) => {
  const { status } = await axios.delete(`${API_URL}/menus/${menuId}`);
  return status === 201;
});

const generateLanguageObject = (languages, type) => {
  return languages.reduce((acc, curr) => {
    acc[curr] = defaultValueByType(type);
    return acc;
  }, {});
};

const mergeLanguageObjects = (languages, payload, type, fallbackLocale = FALLBACK_LOCALE) => {
  return languages.reduce((acc, curr) => {
    acc[curr] = payload[curr] || payload[fallbackLocale] || defaultValueByType(type);
    return acc;
  }, {});
};

export default {
  getDefault,
  getAll,
  getById,

  save,
  duplicate,
  updateById,
  deleteById,

  generateLanguageObject,
  mergeLanguageObjects,
};
