import { Dictionary } from "./@types/common";
import {
  DefaultCurrency,
  ImageRestrictions,
  ImageType,
  MediaType,
  OrderStatus,
  QRCodeType,
  Roles,
  UILanguage,
  UserNetStatus,
} from "./@types/config";

export const APP_MODE = process.env.VUE_APP_MODE;
export const API_DOMAIN_URL = process.env.VUE_APP_API_DOMAIN_URL || "";
export const API_URL = `${process.env.VUE_APP_API_DOMAIN_URL}/${process.env.VUE_APP_API_URL}`;

export const CUSTOMER_URL = process.env.VUE_APP_CUSTOMER_URL || "";
export const LNK_URL = process.env.VUE_APP_LNK_URL;
export const GTM_ID = process.env.VUE_APP_GTM_ID;

export const UI_LANGUAGES: UILanguage[] = [
  {
    code: "en",
    label: "English",
  },
  {
    code: "es",
    label: "Español",
  },
  {
    code: "ru",
    label: "Русский",
  },
  {
    code: "cs",
    label: "Čeština",
  },
  {
    code: "tr",
    label: "Türkçe",
  },
  {
    code: "de",
    label: "Deutsch",
  },
];

export const FALLBACK_LOCALE: string = 'en'

export const DEFAULT_MENU_NAMES: Dictionary<string> = {
  en: "My Menu",
  es: "Mis Menú",
  ru: "Моё меню",
  cs: "Moje menu",
  tr: "Menülerim",
  de: "Meine Menü",
  hu: "A menűm",
  hy: "Իմ մենյու",
  he: "התפריות שלי",
};

export const DEFAULT_CURRENCIES: DefaultCurrency[] = [
  {
    label: "CZK",
    sign: "Kč",
  },
  {
    label: "EUR",
    sign: "€",
  },
  {
    label: "USD",
    sign: "$",
  },
];

export const ORDER_STATUSES: OrderStatus[] = [
  { key: "NEW", label: "StatusNew", color: "#FF9800" },
  { key: "IN_PROGRESS", label: "StatusInProgress", color: "#4CAF50" },
  { key: "SERVED", label: "StatusServed", color: "#23B8FD" },
  { key: "CLOSED", label: "StatusClosed", color: "#78909C" },
  { key: "REJECTED", label: "StatusRejected", color: "#EF5350" },
];

export const QRCODE_TYPES: QRCodeType[] = [
  { key: "WIFI", label: "WiFi", icon: "mdi-wifi" },
  { key: "SUPPLIER", label: "Supplier", icon: "mdi-account-tie" },
  { key: "TABLE", label: "Table", icon: "mdi-table-furniture" },
  { key: "ROOM", label: "Room", icon: "mdi-room-service" },
];

export const MEDIA_TYPES: MediaType[] = [
  { key: "DOCUMENT", label: "Document" },
  { key: "IMAGE", label: "Image" },
  { key: "VIDEO", label: "Video" },
];

export const IMAGE_TYPES: ImageType[] = [
  { key: "AVATAR", label: "Avatar" },
  { key: "COVER", label: "Cover" },
  { key: "LOGO", label: "Logo" },
  { key: "IMAGE", label: "Image" },
];

export const IMAGE_RESTRICTIONS: ImageRestrictions = {
  IMAGE: {
    min: { width: 600, height: 300 },
    max: { width: 6000, height: 6000 },
    aspectRatio: 4 / 3,
  },
  COVER: {
    min: { width: 600, height: 300 },
    max: { width: 6000, height: 6000 },
    aspectRatio: 4 / 3,
  },
  LOGO: {
    min: { width: 300, height: 300 },
    max: { width: 1024, height: 1024 },
    aspectRatio: 1 / 1,
  },
  AVATAR: {
    min: { width: 300, height: 300 },
    max: { width: 1024, height: 1024 },
    aspectRatio: 1 / 1,
  },
};

export const ROLES: Roles = {
  OWNER: "owner",
  MANAGER: "manager",
  WAITER: "waiter",
};

export const PERMISSIONS_GROUPS = [
  "admin",
  "feedback",
  "invite",
  "plans",
  "preorder",
  "profile",
  "review",
  "supplier",
  "web",
] as const;

export const USER_NET_STATUS: UserNetStatus[] = [
  { key: "ONLINE", label: "Online", color: "green" },
  { key: "RECENTLY", label: "Recently", color: "orange" },
  { key: "OFFLINE", label: "Offline", color: "grey" },
];
