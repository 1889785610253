import { GetterTree } from "vuex";
import { AppState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<AppState, RootState> = {
  version: (state) => state.version,
  theme: (state) => state.theme,
  sounds: (state) => state.sounds,
  settings: (state) => state.settings,

  defaultLanguage: (state) => state.settings.defaultLanguage,
  alternativeLanguages: (state) => state.settings.alternativeLanguages,
  defaultCurrency: (state) => state.settings.defaultCurrency,
};
