



































import { Component, Watch, Vue } from "vue-property-decorator";
import { Sync } from "vuex-pathify";

const IN_BROWSER = typeof window !== "undefined";

@Component({ name: "VTheme" })
export default class VTheme extends Vue {
  dark: boolean = false;
  system: boolean = false;
  mixed: boolean = false;

  @Sync("app/theme")
  theme!: string | null;

  get items() {
    return [
      {
        key: "light",
        label: this.$t("GlobalUI.ThemeLight"),
        icon: "mdi-white-balance-sunny",
        cb: () => this.setTheme(),
      },
      {
        key: "dark",
        label: this.$t("GlobalUI.ThemeDark"),
        icon: "mdi-weather-night",
        cb: () => this.setTheme(true),
      },
      // {
      //   key: "system",
      //   label: "System",
      //   icon: "mdi-desktop-tower-monitor",
      //   cb: () => this.setSystemTheme(),
      // },
      // {
      //   key: "mixed",
      //   label: "Mixed",
      //   icon: "mdi-theme-light-dark",
      //   cb: () => this.setTheme(false, true),
      // },
    ];
  }

  get currentTheme() {
    if (this.mixed) return "mixed";
    if (this.system) return "system";
    return this.dark ? "dark" : "light";
  }
  set currentTheme(val) {
    const set = this.items.find((item) => item.key === val);
    if (!set) return;

    set.cb();
    localStorage.setItem("theme", set.key);
  }

  @Watch("$vuetify.theme.dark")
  onVuetifyThemeDarkChanged(val: boolean) {
    this.theme = val ? "dark" : "light";

    if (this.dark === val) return;
    this.dark = val;
  }

  @Watch("dark")
  onDarkChanged(val: boolean) {
    if (this.$vuetify.theme.dark === val) return;
    this.$vuetify.theme.dark = val;
  }

  created() {
    const theme = localStorage.getItem("theme");

    if (theme && theme === "dark") this.dark = true;
    if (theme && theme === "system") this.system = true;
    if (theme && theme === "mixed") this.mixed = true;

    const matchMedia = this.getMatchMedia();
    if (!matchMedia) return;
    if (this.currentTheme === "system") {
      this.dark = matchMedia.matches;
    }
    matchMedia.onchange = ({ matches }) => {
      if (this.system) {
        this.dark = matches;
      }
    };
  }

  getMatchMedia() {
    return IN_BROWSER && window.matchMedia
      ? window.matchMedia("(prefers-color-scheme: dark)")
      : false;
  }

  setTheme(dark = false, mixed = false, system = false) {
    this.dark = dark;
    this.mixed = mixed;
    this.system = system;
  }

  setSystemTheme() {
    const matchMedia = this.getMatchMedia();
    if (!matchMedia) return;
    this.setTheme(matchMedia.matches, this.mixed, true);
  }
}
