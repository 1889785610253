import { ActionTree } from "vuex";
import { CountriesState, types } from "./types";
import { RootState } from "../types";

import countries from "../../countries.json";

export const actions: ActionTree<CountriesState, RootState> = {
  fetchCountries({ commit }) {
    commit(types.SET_COUNTRIES, countries);

    const languages = countries
      .map(({ languages }) => languages)
      .flat()
      .map((language) => ({ code: language.iso639_1 || "", label: language.name }))
      .filter((language) => language.code)
      .filter((language, idx, self) => idx === self.findIndex((l) => l.code === language.code))
      .sort((a, b) => a.code.localeCompare(b.code));

    commit(types.SET_LANGUAGES, languages);
  },

  clearCountries({ commit }) {
    commit(types.SET_COUNTRIES, []);
  },
};
