import { MenuModel } from "@/@types/model";

export interface MenusState {
  menus: MenuModel[];

  isMenusLoading: boolean;
  menusError: string | null;
}

export enum types {
  REQUESTED_MENUS = "MENUS/REQUESTED_MENUS",
  REQUESTED_MENUS_SUCCEEDED = "MENUS/REQUESTED_MENUS_SUCCEEDED",
  REQUESTED_MENUS_FAILED = "MENUS/REQUESTED_MENUS_FAILED",

  ADD_MENU = "MENUS/ADD_MENU",
  UPDATE_MENU = "MENUS/UPDATE_MENU",
  DELETE_MENU = "MENUS/DELETE_MENU",
  CLEAR_MENUS = "MENUS/CLEAR_MENUS",
}
