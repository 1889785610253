<template>
  <v-card class="our-visitors-card" elevation="2">
    <v-toolbar class="our-visitors-card__toolbar" flat>
      <v-toolbar-title> {{ $t("Dashboard.OurVisitorsCardHeader") }} </v-toolbar-title>
      <v-spacer />
      <div :style="{ whiteSpace: 'nowrap' }" class="orange--text">
        {{ $t("GlobalUI.ComingSoon") }}
      </div>
    </v-toolbar>

    <v-divider />

    <div class="our-visitors-card__body">
      <apexchart
        v-if="!isLoading"
        type="donut"
        height="260px"
        :options="options"
        :series="series"
      />
      <div
        v-else
        class="d-flex justify-center align-center"
        :style="{ height: '100%', minHeight: '238px' }"
      >
        <v-progress-circular color="primary" indeterminate />
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { fakeRequest } from "../../services";

export default {
  name: "VOurVisitorsCard",

  components: { apexchart: VueApexCharts },

  data() {
    return {
      series: [44, 55, 41, 17],

      isLoading: true,
    };
  },

  computed: {
    options() {
      return {
        chart: {
          background: "transparent",
        },
        labels: [
          this.$t("GlobalUI.DeviceTypeMobile"),
          this.$t("GlobalUI.DeviceTypeDesktop"),
          this.$t("GlobalUI.DeviceTypeTablet"),
          this.$t("GlobalUI.DeviceTypeOther"),
        ],
        colors: ["#673AB7", "#00BCD4", "#009688", "#607D8B"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
      };
    },
  },

  async created() {
    await fakeRequest(3000);
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.our-visitors-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  &__body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}
</style>