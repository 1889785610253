import { MutationTree } from "vuex";

import { CountriesState, types } from "./types";

export const mutations: MutationTree<CountriesState> = {
  [types.SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [types.SET_LANGUAGES](state, languages) {
    state.languages = languages;
  },
};
