import { DialogAction } from "vuetify-dialog";

type NotificationType = "success" | "warning" | "info" | "error";
type NotifyPosition = "top" | "top-right" | "bottom";
type NotifyManager = "notify" | "message";

export interface NotificationModel {
  id: string;
  icon: string;
  type: NotificationType;
  group?: string;
  text: string;
  date: Date;
  actions: DialogAction[];
  isDashboard: boolean;
  isNotify: boolean;
}

export interface NotifyModel {
  id: string;
  manager: NotifyManager;
  text: string;
  type: NotificationType;
  timeout: number;
  position: NotifyPosition;
  actions?: DialogAction[];
}

export interface NotificationsState {
  notifications: NotificationModel[];
  notify: NotifyModel | null;
}

export enum types {
  SET_NOTIFICATIONS = "SET_NOTIFICATIONS",
  SET_NOTIFY = "SET_NOTIFY",
}
