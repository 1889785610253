import { Sync } from "vuex-pathify";
import { Component, Vue } from "vue-property-decorator";

import { netStatusEvents } from "@/constants/events";

@Component
export default class NetStatus extends Vue {
  @Sync("auth/user@netStatus") userNetStatus!: string;
  @Sync("app/netStatus") appNetworkStatus!: string;

  created() {
    if (typeof window !== "undefined") {
      const onlineHandler = () => {
        this.userNetStatus = 'ONLINE'
        this.appNetworkStatus = 'ONLINE'
        this.$emit(netStatusEvents.ONLINE);
      };

      const offlineHandler = () => {
        this.userNetStatus = 'OFFLINE'
        this.appNetworkStatus = 'OFFLINE'
        this.$emit(netStatusEvents.OFFLINE);
      };

      window.addEventListener("online", onlineHandler);
      window.addEventListener("offline", offlineHandler);

      this.$once("hook:beforeDestroy", () => {
        window.removeEventListener("online", onlineHandler);
        window.removeEventListener("offline", offlineHandler);
      });
    }
  }
}
