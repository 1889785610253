import { MutationTree } from "vuex";

import { MenusState, types } from "./types";

export const mutations: MutationTree<MenusState> = {
  [types.REQUESTED_MENUS](state) {
    state.isMenusLoading = true;
  },

  [types.REQUESTED_MENUS_SUCCEEDED](state, payload) {
    state.menus = payload;
    state.isMenusLoading = false;
  },

  [types.REQUESTED_MENUS_FAILED](state, error) {
    state.isMenusLoading = false;
    state.menusError = error;
  },

  [types.ADD_MENU](state, payload) {
    state.menus = [payload, ...state.menus];
  },

  [types.UPDATE_MENU](state, { id, payload }) {
    state.menus = state.menus.map((menu) =>
      menu.id === id ? Object.assign({}, menu, payload) : menu,
    );
  },

  [types.DELETE_MENU](state, id) {
    state.menus = state.menus.filter((menu) => menu.id !== id);
  },

  [types.CLEAR_MENUS](state) {
    state.menus = [];

    state.isMenusLoading = false;
    state.menusError = null;
  },
};
