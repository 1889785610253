import { ActionTree } from "vuex";
import i18n from "@/plugins/i18n";

import { AppState, types } from "./types";
import { RootState } from "../types";

import suppliersService from "@/services/suppliers.service";

import { CUSTOMER_URL } from "../../config";
import { AppSettingsModel, SupplierModel } from "@/@types/model";

export const actions: ActionTree<AppState, RootState> = {
  async fetchSettings({ commit, rootState }) {
    commit(types.REQUESTED_SETTINGS);
    try {
      const supplierId = rootState.auth.user.currentSupplier!.id;
      const supplierSettings = await suppliersService.getFieldsById(supplierId, [
        "isFirstConfig",

        "regionSettings.country",
        "regionSettings.timeFormat",
        "regionSettings.firstDayOfWeek",
        "defaultCurrency",
        "defaultLanguage",
        "alternativeLanguages",
        "vatRates",
        "subdomain",
        "linkId",
      ]);
      if (!supplierSettings) throw Error(`${i18n.t('InternalError.SupplierSettingsNotFound')}`);

      const {
        isFirstConfig,
        regionSettings,
        defaultCurrency,
        defaultLanguage,
        alternativeLanguages,
        vatRates,
        subdomain,
        linkId,
      } = supplierSettings as SupplierModel;

      const appSettings: AppSettingsModel = {
        isFirstConfig: isFirstConfig ?? true,
        customerURL: CUSTOMER_URL.replace("{subdomain}", subdomain),
        country: regionSettings.country,
        timeFormat: regionSettings.timeFormat,
        firstDayOfWeek: regionSettings.firstDayOfWeek || "mon",
        defaultCurrency: defaultCurrency.sign,
        defaultLanguage,
        alternativeLanguages,
        vatRates,
        subdomain,
        linkId,
      };

      commit(types.REQUESTED_SETTINGS_SUCCEEDED, appSettings);
    } catch (error) {
      commit(types.REQUESTED_SETTINGS_FAILED, error);
      throw error
    }
  },

  async updateSettings({ commit }, payload) {
    commit(types.UPDATE_SETTINGS, payload);
  },
};
