










import { Component as VueComponent } from "vue";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Get } from "vuex-pathify";

import VLoading from "@/components/VLoading.vue";
import NetStatus from "@/mixins/netStatus";

import { UserModel } from "./@types/model";

// Load layout components dynamically.
const requireContext = require.context("./layouts", false, /.*\.vue$/);
const layouts: { [key: string]: VueComponent } = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components: any, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

@Component({ components: { VLoading }, mixins: [NetStatus] })
export default class App extends mixins(NetStatus) {
  private layout: VueComponent | null = null;
  private defaultLayout: string = "default";

  @Get("auth/user") user!: UserModel;
  @Get("app/theme") theme!: string;

  async created() {
    this.$vuetify.theme.dark = this.theme === "dark";
    await this.$store.dispatch("countries/fetchCountries");
  }

  mounted() {
    this.$loading = this.$refs.loading as VueComponent;
  }

  setLayout(layout: string) {
    if (!layout || !layouts[layout]) {
      layout = this.defaultLayout;
    }
    this.layout = layouts[layout];
  }
}
