import { MenuModel, MenuSettingsModel } from "@/@types/model";

export interface MenuState {
  cacheMenu: MenuModel | null;
  menu: MenuModel;
  menuSettings: MenuSettingsModel | null;
  selectedCategoryId: string | number | null;

  isMenuLoading: boolean;
  isMenuSettingsLoading: boolean;
  isMenuBusy: boolean;
  menuError: string | null;

  menus: MenuModel[];
  isMenusLoading: boolean;
  menusError: string | null;
}

export enum types {
  /* Fetch Menu */
  REQUESTED_MENU = "MENU/REQUESTED_MENU",
  REQUESTED_MENU_SUCCEEDED = "MENU/REQUESTED_MENU_SUCCEEDED",
  REQUESTED_MENU_FAILED = "MENU/REQUESTED_MENU_FAILED",

  /* Fetch Menu Settings */
  REQUESTED_MENU_SETTINGS = "MENU/REQUESTED_MENU_SETTINGS",
  REQUESTED_MENU_SETTINGS_SUCCEEDED = "MENU/REQUESTED_MENU_SETTINGS_SUCCEEDED",
  REQUESTED_MENU_SETTINGS_FAILED = "MENU/REQUESTED_MENU_SETTINGS_FAILED",

  /* Fetch Menus */
  REQUESTED_MENUS = "MENU/REQUESTED_MENUS",
  REQUESTED_MENUS_SUCCEEDED = "MENU/REQUESTED_MENUS_SUCCEEDED",
  REQUESTED_MENUS_FAILED = "MENU/REQUESTED_MENUS_FAILED",

  /* Save Menu */
  REQUESTED_SAVE_MENU = "MENU/REQUESTED_SAVE_MENU",
  REQUESTED_SAVE_MENU_SUCCEEDED = "MENU/REQUESTED_SAVE_MENU_SUCCEEDED",
  REQUESTED_SAVE_MENU_FAILED = "MENU/REQUESTED_SAVE_MENU_FAILED",

  /* Menus */
  CLEAR_MENUS = "MENU/CLEAR_MENUS",

  /* Menu */
  SET_MENU = "MENU/SET_MENU",
  SET_MENU_FIELD = "MENU/SET_MENU_FIELD",
  SET_MENU_TRANSLATE_FIELD = "MENU/SET_MENU_TRANSLATE_FIELD",
  UPDATE_MENU = "MENU/UPDATE_MENU",
  CLEAR_MENU = "MENU/CLEAR_MENU",

  /* Cache Menu */
  SET_CACHE_MENU = "MENU/SET_CACHE_MENU",
  UPDATE_CACHE_MENU = "MENU/UPDATE_CACHE_MENU",

  /* Categories */
  ADD_CATEGORY = "MENU/ADD_CATEGORY",
  UPDATE_CATEGORY = "MENU/UPDATE_CATEGORY",
  DELETE_CATEGORY = "MENU/DELETE_CATEGORY",

  /* Dishes */
  SET_DISHES = "MENU/SET_DISHES",
  ADD_DISH = "MENU/ADD_DISH",
  DELETE_DISH = "MENU/DELETE_DISH",
  UPDATE_DISH = "MENU/UPDATE_DISH",

  /* Other */
  SET_SELECTED_CATEGORY = "menu/SET_SELECTED_CATEGORY",
  CLEAR_SELECTED_CATEGORY = "menu/CLEAR_SELECTED_CATEGORY",
}
