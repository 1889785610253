import Cookies from "js-cookie";

import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { LangState } from "./types";
import { RootState } from "../types";

import { UI_LANGUAGES } from "../../config";

const locales = UI_LANGUAGES;

const defaultLocale = Cookies.get("locale") || navigator.language.split(/-|_/).shift() || "";
const isCheckLanguage = UI_LANGUAGES.map((language) => language.code).includes(defaultLocale);

export const state: LangState = {
  locale: isCheckLanguage ? defaultLocale : "en",
  locales,
};

const namespaced: boolean = true;

export const lang: Module<LangState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
