import { MenuModel, VatRate } from "@/@types/model";

interface MenuSettings {
  defaultCurrency: string;
  defaultLanguage: string;
  alternativeLanguages: string[];
  vatRates: VatRate[];
}

export interface LocalizerState {
  isLoading: boolean;
  isMenuBusy: boolean;
  isMenuSettingsLoading: boolean;

  cacheMenu: MenuModel | null;
  menu: MenuModel | null;
  menuSettings: MenuSettings | null;

  selectedCategory: string | null;
  selectedDish: string | null;

  selectType: "MENU" | "CATEGORY" | "DISH" | null;

  translateLanguage: string | null;
}

export enum types {
  REQUESTED_MENU = "LOCALIZER/REQUESTED_MENU",
  REQUESTED_MENU_SUCCEEDED = "LOCALIZER/REQUESTED_MENU_SUCCEEDED",
  REQUESTED_MENU_FAILED = "LOCALIZER/REQUESTED_MENU_FAILED",

  REQUESTED_SAVE_MENU = "LOCALIZER/REQUESTED_SAVE_MENU",
  REQUESTED_SAVE_MENU_SUCCEEDED = "LOCALIZER/REQUESTED_SAVE_MENU_SUCCEEDED",
  REQUESTED_SAVE_MENU_FAILED = "LOCALIZER/REQUESTED_SAVE_MENU_FAILED",

  DISCARD_MENU = "LOCALIZER/DISCARD_MENU",

  REQUESTED_MENU_SETTINGS = "LOCALIZER/REQUESTED_MENU_SETTINGS",
  REQUESTED_MENU_SETTINGS_SUCCEEDED = "LOCALIZER/REQUESTED_MENU_SETTINGS_SUCCEEDED",
  REQUESTED_MENU_SETTINGS_FAILED = "LOCALIZER/REQUESTED_MENU_SETTINGS_FAILED",

  SET_SELECTED_MENU = "LOCALIZER/SET_SELECTED_MENU",
  SET_SELECTED_CATEGORY = "LOCALIZER/SET_SELECTED_CATEGORY",
  SET_SELECTED_DISH = "LOCALIZER/SET_SELECTED_DISH",

  SET_SELECT_TYPE = "LOCALIZER/SET_SELECT_TYPE",

  CLEAR_LOCALIZER = "LOCALIZER/CLEAR_LOCALIZER",
}
