export interface Locale {
  code: string;
  label: string;
}

export interface LangState {
  locale: string;
  locales: Locale[];
}

export enum types {
  SET_LOCALE = "SET_LOCALE",
}
