




























import { Component, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import { loadMessages } from "@/plugins/i18n";

import { Locale } from "@/store/lang/types";

@Component({ name: "VLocaleDropdown" })
export default class VLocaleDropdown extends Vue {
  @Get("lang/locale")
  locale!: string;

  @Get("lang/locales")
  locales!: Locale[];

  setLocale(locale: string) {
    if (this.$i18n.locale !== locale) {
      loadMessages(locale);
      this.$store.dispatch("lang/setLocale", { locale });
    }
  }
}
