<template>
  <v-card class="orders-heatmap-card" elevation="2">
    <v-toolbar class="orders-heatmap-card__toolbar" flat>
      <v-toolbar-title> {{ $t("Dashboard.OrdersHeatmapCardHeader") }} </v-toolbar-title>
      <v-spacer />
      <div :style="{ whiteSpace: 'nowrap' }" class="orange--text">
        {{ $t("GlobalUI.ComingSoon") }}
      </div>
    </v-toolbar>

    <v-divider />

    <div class="orders-heatmap-card__body">
      <apexchart
        v-if="!isLoading"
        height="310"
        type="heatmap"
        :options="options"
        :series="series"
      />
      <div v-else class="d-flex justify-center align-center" :style="{ height: '310px' }">
        <v-progress-circular color="primary" indeterminate />
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import { fakeRequest } from "../../services";

const generateData = (count, xrange, yrange) => {
  let i = 0;
  const series = [];
  while (i < count) {
    if (i < xrange.min || i >= xrange.max) {
      series.push({ x: (i + 1).toString(), y: 0 });
      i++;
      continue;
    }
    const x = (i + 1).toString();
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push({ x, y });
    i++;
  }
  return series;
};

export default {
  name: "VOrdersHeatmapCard",

  components: { apexchart: VueApexCharts },

  data() {
    return {
      series: [],

      isLoading: true,
    };
  },

  async created() {
    await fakeRequest(3000);
    this.isLoading = false;

    const weekdays = [...new Array(7)].map((_, i) =>
      moment(i, "e")
        .locale(this.locale)
        .startOf("week")
        .isoWeekday(i + 1)
        .format("ddd"),
    );

    const currentHour = moment().format("HH");
    if (currentHour === "00") return 24;

    this.series = weekdays.map((weekday) => ({
      name: weekday,
      data: generateData(24, { min: 9, max: Number(currentHour) }, { min: 0, max: 90 }),
    }));
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      appSettings: "app/settings",
    }),
    options() {
      const timeFormat = this.appSettings.timeFormat;
      const times = this.timelineLabels("00:00", 1, "hours", timeFormat);

      return {
        chart: {
          width: "100%",
          background: "transparent",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#009688"],
        xaxis: {
          type: "category",
          categories: times,
          labels: {
            style: {
              colors: this.$vuetify.theme.dark ? "white" : "black",
            },
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
      };
    },
  },

  methods: {
    timelineLabels(desiredStartTime, interval, period, timeFormat) {
      const periodsInADay = moment.duration(1, "day").as(period);

      const stringTimeFormat = timeFormat === 12 ? "hh A" : "HH";
      const timeLabels = [];
      const startTimeMoment = moment(desiredStartTime, "hh:mm");
      for (let i = 1; i < periodsInADay; i += interval) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        timeLabels.push(startTimeMoment.format(stringTimeFormat));
      }

      timeLabels.push(timeFormat === 12 ? "12" : "24");

      return timeLabels;
    },
  },
};
</script>

<style lang="scss">
.orders-heatmap-card {
  height: 100%;
  &__body {
    padding: 15px;
  }
}
</style>