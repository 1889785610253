<script>
import { VList } from "vuetify/lib";

export default {
  name: "VListExtend",
  extends: VList,

  props: {
    xDense: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        ...VList.options.computed.classes.call(this),
        "v-list--x-dense": this.xDense,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list::v-deep .v-list-item .v-list-item__icon {
  margin-right: 4px;
}

.v-list::v-deep.v-list--x-dense .v-list-item {
  min-height: 32px;
  margin-bottom: 4px;
  .v-list-item__icon {
    height: 24px;
    margin: 4px;
    margin-left: 0;
  }
  .v-list-item__title {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1rem;
  }
  .v-list-item__content {
    padding: 4px 0;
  }
}
</style>