import axios from "axios";
import i18n from "./i18n";
import store from "../store";
import router from "../router";

// Request interceptor
axios.interceptors.request.use((request) => {
  const token = store.getters["auth/token"];
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  }

  request.config = request.config ?? { showNotify: true };

  return request;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { data, status } = error.response;
    const code = data.code;
    const { config } = error.response.config;

    if (status === 401 && store.getters["auth/check"]) {
      await store.dispatch("auth/logout");
      router.push({ name: "login", params: { isTokenExpired: "true" } });
    }

    if (status === 404 && !store.getters["auth/check"]) {
      await store.dispatch("auth/logout");
      if (router.currentRoute.name !== "login") router.push({ name: "login" });
    }

    if (status === 404 && store.getters["auth/check"]) {
      router.push({ name: "error.404" });
    }

    if (config.showNotify && status >= 400 && code) {
      store.set("notifications/notify", {
        manager: "notify",
        text: i18n.t(`ServerResponse.${code}`),
        type: "error",
        timeout: -1,
      });
    }

    if (status >= 500 && !code) {
      router.push({ name: "error.500" });
    }

    return Promise.reject(error);
  },
);
