import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";

import { app } from "./app";
import { lang } from "./lang";
import { auth } from "./auth";
import { categories } from "./categories";
import { orders } from "./orders";
import { notifications } from "./notifications";
import { media } from "./media";
import { countries } from "./countries";
import { menu } from "./menu";
import { localizer } from "./localizer";
import { menus } from "./menus";

import { RootState } from "./types";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    app,
    lang,
    auth,
    categories,
    orders,
    notifications,
    media,
    countries,
    menu,
    localizer,
    menus,
  },
  plugins: [pathify.plugin],
  strict: process.env.NODE_ENV !== "production",
});
