import { UserModel } from "@/@types/model";
import catchErrors from "@/helpers/catchErrors";
import axios from "axios";

import { API_URL } from "../config";

export interface LoginResponse {
  token: string;
}

const login = catchErrors<LoginResponse | null>(
  async (email: string, password: string) => {
    const { data, status } = await axios.post<LoginResponse>(
      `${API_URL}/login`,
      { email, password },
      { config: { showNotify: false } },
    );
    return status === 200 && data ? data : null;
  },
  { isThrow: true },
);

const logout = catchErrors<number>(
  async ({ id: userId, currentSupplier }: UserModel) => {
    if (!currentSupplier) return false;
    const { status } = await axios.post(`${API_URL}/logout`, {
      userId,
      supplierId: currentSupplier.id,
    });
    return status === 200;
  },
  { isThrow: true },
);

const getCurrentUser = catchErrors<UserModel | null>(
  async () => {
    const { data, status } = await axios.get<UserModel>(`${API_URL}/user`);
    return status === 200 && data ? data : null;
  },
  { isThrow: true },
);

export default { login, logout, getCurrentUser };
