import { Module } from "vuex";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { OrdersState } from "./types";
import { RootState } from "../types";

export const state: OrdersState = {
  orders: [],

  isOrdersLoading: true,
  ordersError: null,
};

const namespaced: boolean = true;

export const orders: Module<OrdersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
