import { Component, Vue } from "vue-property-decorator";

import { APP_MODE } from "@/config";

@Component
export default class Envirements extends Vue {
  get isLocal() {
    return APP_MODE === "local";
  }

  get isDevelopment() {
    return APP_MODE === "development";
  }

  get isProduction() {
    return APP_MODE === "production";
  }
}
