export const stringify = (obj, sep, eq) => {
  sep = sep || "&";
  eq = eq || "=";

  if (obj !== null && typeof obj === "object") {
    const keys = Object.keys(obj);
    const len = keys.length;
    let fields = "";

    for (let i = 0; i < len; ++i) {
      const k = keys[i];
      const v = obj[k];
      let ks = k;
      ks += eq;

      if (Array.isArray(v)) {
        const vlen = v.length;
        if (vlen === 0) continue;
        if (fields) fields += sep;
        for (let j = 0; j < vlen; ++j) {
          if (j) fields += sep;
          fields += ks;
          fields += v[j];
        }
      } else {
        if (fields) fields += sep;
        fields += ks;
        fields += v;
      }
    }
    return `?${fields}`;
  }

  return "";
};
