








import { Component, Vue } from "vue-property-decorator";
import { Sync } from "vuex-pathify";

import { Sounds } from "@/store/app/types";

@Component({ name: "VSounds" })
export default class VSounds extends Vue {
  @Sync("app/sounds@order") order!: Sounds;
}
