import { MutationTree } from "vuex";
import { make } from "vuex-pathify";
import Cookies from "js-cookie";

import { AuthState, types } from "./types";
import { UserSchema } from ".";

export const mutations: MutationTree<AuthState> = {
  ...make.mutations(["user"]),

  [types.SAVE_TOKEN](state, { token, remember }) {
    state.token = token;
    Cookies.set("token", token, { expires: remember ? 365 : undefined });
  },

  [types.FETCH_USER_SUCCESS](state, user) {
    state.user = { ...state.user, ...user };
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove("token");
  },

  [types.LOGOUT](state) {
    state.token = null;
    Cookies.remove("token");
  },

  [types.CLEAR_USER](state) {
    state.user = UserSchema();
  },

  [types.UPDATE_USER](state, payload) {
    state.user = {
      ...state.user,
      ...payload,
    };
  },
};
