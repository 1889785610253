































import { Component, Vue } from "vue-property-decorator";

import VTheme from "./settings/dropdown/VTheme.vue";
import VSounds from "./settings/dropdown/VSounds.vue";

@Component({
  name: "VAppSettingsDropdown",
  components: { VTheme, VSounds },
})
export default class VAppSettingsDropdown extends Vue {
  isShow: boolean = false;
}
