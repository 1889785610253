export enum menuEvents {
  SHOW_MENU_SCHEDULE = "MENU/SHOW_MENU_SCHEDULE",
  SHOW_MENU_PREVIEW = "MENU/SHOW_MENU_PREVIEW",
  SHOW_MENU_SETTINGS = "MENU/SHOW_MENU_SETTINGS",
}

export enum settingsEvents {
  SAVE_SETTINGS = "SETTINGS/SAVE_SETTINGS",
  RESTORE_SETTINGS = "SETTINGS/RESTORE_SETTINGS",
}

export enum netStatusEvents {
  ONLINE = "NETWORK_STATUS/ONLINE",
  OFFLINE = "NETWORK_STATUS/OFFLINE",
}
