



























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "VMenuPreview" })
export default class VMenuPreview extends Vue {
  @Prop(String) readonly url!: string;

  deviceType: "mobile" | "tablet" = "mobile";
  localUrl: string = this.url;

  @Watch("url")
  onUrlChanged(value: string) {
    this.localUrl = value;
  }
}
