import { CategoryModel } from "@/@types/model";

export interface CategoriesState {
  categories: CategoryModel[];
  isLoading: boolean;
}

export enum types {
  SET_CATEGORIES_LOADING = "CATEGORIES/SET_CATEGORIES_LOADING",

  SET_CATEGORIES = "CATEGORIES/SET_CATEGORIES",
  ADD_CATEGORY = "CATEGORIES/ADD_CATEGORY",
  UPDATE_CATEGORY = "CATEGORIES/UPDATE_CATEGORY",
  DELETE_CATEGORY = "CATEGORIES/DELETE_CATEGORY",
  CLEAR_CATEGORIES = "CATEGORIES/CLEAR_CATEGORIES",
}
