










import { Component, Vue } from "vue-property-decorator";

@Component({ name: "ErrorLayout" })
export default class ErrorLayout extends Vue {}
