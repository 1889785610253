import { Component, Vue } from "vue-property-decorator";

const BREAKPOINTS = {
  xs: 340,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  // xxl: 1400px
};

@Component
export default class Breakpoints extends Vue {
  get isMobile() {
    return this.$vuetify.breakpoint.width <= BREAKPOINTS.sm;
  }

  get isTablet() {
    return this.$vuetify.breakpoint.width <= BREAKPOINTS.md;
  }
}
