export interface OrdersState {
  orders: { [key: string]: any }[];

  isOrdersLoading: boolean;
  ordersError: string | null;
}

export enum types {
  REQUESTED_ORDERS = "ORDERS/REQUESTED_ORDERS",
  REQUESTED_ORDERS_SUCCEEDED = "ORDERS/REQUESTED_ORDERS_SUCCEEDED",
  REQUESTED_ORDERS_FAILED = "ORDERS/REQUESTED_ORDERS_FAILED",

  ADD_ORDER = "ORDERS/ADD_ORDER",
  CLEAR_ORDERS = "ORDERS/CLEAR_ORDERS",
}
