import { GetterTree } from "vuex";
import { CountriesState, Language } from "./types";
import { RootState } from "../types";

import countriesService from "@/services/countries.service";

export const getters: GetterTree<CountriesState, RootState> = {
  countries: (state) => state.countries,
  languages: (state) => state.languages,
  languagesForCountry:
    (state) =>
    (country: string): Language[] => {
      if (!country) return state.languages;
      return countriesService.generateLanguagesForCountry(
        country,
        state.countries,
        state.languages,
      );
    },
};
