import axios from "axios";

import { API_URL } from "../config";

const update = async (user) => {
  const { status } = await axios.patch(`${API_URL}/user`, user);
  return status === 200;
};

export default { update };
