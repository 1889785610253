import { Module } from "vuex";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { NotificationsState } from "./types";
import { RootState } from "../types";

export const state: NotificationsState = {
  notifications: [],
  notify: null,
};

const namespaced: boolean = true;

export const notifications: Module<NotificationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
