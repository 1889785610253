import { MutationTree } from "vuex";
import { make } from "vuex-pathify";

import { CUSTOMER_URL } from "../../config";

import { AppState, types } from "./types";

export const mutations: MutationTree<AppState> = {
  ...make.mutations(["sounds", "theme", "netStatus"]),

  [types.REQUESTED_SETTINGS](state) {
    state.isSettingsLoading = true;
  },

  [types.REQUESTED_SETTINGS_SUCCEEDED](state, payload) {
    state.settings = payload;
    state.isSettingsLoading = false;
  },

  [types.REQUESTED_SETTINGS_FAILED](state, error) {
    state.isSettingsLoading = false;
    state.settingsError = error;
  },

  [types.UPDATE_SETTINGS](state, payload) {
    state.settings = {
      ...state.settings,
      ...payload,
      customerURL: CUSTOMER_URL.replace(
        "{subdomain}",
        payload.subdomain || state.settings.subdomain,
      ),
    };
  },
};
