<template>
  <div v-if="show" :class="`app-loader app-loader--${theme}`">
    <v-progress-circular
      class="app-loader__progress"
      :value="percent"
      :rotate="360"
      :size="80"
      :width="10"
    >
      {{ percent }}
    </v-progress-circular>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VLoading",

  data: () => ({
    percent: 0,
    show: false,
    duration: 3000,
  }),

  computed: mapGetters({ theme: "app/theme" }),

  methods: {
    start() {
      this.show = true;
      this.canSuccess = true;
      if (this._timer) {
        clearInterval(this._timer);
        this.percent = 0;
      }
      this._cut = 10000 / Math.floor(this.duration);
      this._timer = setInterval(() => {
        this.increase(this._cut * Math.random());
        if (this.percent > 95) {
          this.finish();
        }
      }, 100);
      return this;
    },
    set(num) {
      this.show = true;
      this.canSuccess = true;
      this.percent = Math.floor(num);
      return this;
    },
    get() {
      return Math.floor(this.percent);
    },
    increase(num) {
      this.percent = this.percent + Math.floor(num);
      return this;
    },
    decrease(num) {
      this.percent = this.percent - Math.floor(num);
      return this;
    },
    finish() {
      this.percent = 100;
      this.hide();
      return this;
    },
    pause() {
      clearInterval(this._timer);
      return this;
    },
    hide() {
      clearInterval(this._timer);
      this._timer = null;
      setTimeout(() => {
        this.show = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.percent = 0;
          }, 200);
        });
      }, 500);
      return this;
    },
    fail() {
      this.canSuccess = false;
      return this;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-loader {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: Roboto, sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;

  &--dark {
    background-color: #272727;
  }

  &__progress {
    color: $primary-color;
  }
}
</style>