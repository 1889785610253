import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      variations: false,
    },
    themes: {
      light: {
        primary: "#009688",
        secondary: "#e9f8f7",
        accent: "#20BCAD",
      },
      dark: {
        primary: "#009688",
        secondary: "#e9f8f7",
        accent: "#20BCAD",
      },
    },
  },
});

export default vuetify;
