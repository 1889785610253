<template>
  <v-card class="reviews-card" elevation="2">
    <v-toolbar class="reviews-card__toolbar" flat>
      <v-toolbar-title> {{ $t("Dashboard.ReviewsCardHeader") }} </v-toolbar-title>
      <v-spacer />
      <div :style="{ whiteSpace: 'nowrap' }" class="orange--text">
        {{ $t("GlobalUI.ComingSoon") }}
      </div>
    </v-toolbar>

    <v-divider />

    <div class="reviews-card__body">
      <div v-if="!isLoading" class="reviews">
        <div class="reviews-left">
          <h2 class="font-weight-light display-1">{{ total }}</h2>
          <h6 class="font-weight-light subtitle-2 blue-grey--text text--lighten-2">
            {{ currentMonth }} the product got 234 reviews
          </h6>
          <div class="reviews-avatars">
            <img
              class="rounded-circle mr-2"
              src="https://i.pravatar.cc/45?u=a042581f4e29026704d"
              alt="user"
              width="45"
            />
            <img
              class="rounded-circle mr-2"
              src="https://i.pravatar.cc/45?u=a042582f4e29026704d"
              alt="user"
              width="45"
            />
            <img
              class="rounded-circle mr-2"
              src="https://i.pravatar.cc/45?u=a042581f4d29026704d"
              alt="user"
              width="45"
            />
            <img
              class="rounded-circle mr-2"
              src="https://i.pravatar.cc/45?u=a042581f4e29026304d"
              alt="user"
              width="45"
            />
          </div>
          <v-btn class="mt-5" color="primary" small>{{ $t("Dashboard.ReadReviews") }}</v-btn>
        </div>
        <div class="reviews-right">
          <div class="review-list">
            <div class="review" v-for="(review, idx) in reviews" :key="idx">
              <div class="d-flex align-center">
                <v-icon class="display-2 blue-grey--text text--lighten-2">{{ review.icon }}</v-icon>
                <div class="ml-3">
                  <h2 class="review__title">{{ review.label }}</h2>
                  <span class="subtitle-2 font-weight-light">
                    {{ review.count }} {{ $t("Dashboard.Reviews") }}
                  </span>
                </div>
              </div>
              <v-progress-linear
                class="review__progress"
                :color="review.color"
                :value="review.value"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
        :style="{ height: '100%', minHeight: '238px' }"
      >
        <v-progress-circular color="primary" indeterminate />
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

import { fakeRequest } from "../../services";

export default {
  name: "VOurVisitorsCard",

  data() {
    return {
      total: 31560,
      currentMonth: moment(new Date()).format("MMM"),

      isLoading: true,
    };
  },

  computed: {
    reviews() {
      return [
        {
          label: this.$t("Dashboard.Positive"),
          count: 25547,
          icon: "mdi-emoticon-cool",
          color: "green",
          value: (25547 / this.total) * 100,
        },
        {
          label: this.$t("Dashboard.Neutral"),
          count: 5547,
          icon: "mdi-emoticon-neutral",
          color: "orange",
          value: (5547 / this.total) * 100,
        },
        {
          label: this.$t("Dashboard.Negative"),
          count: 547,
          icon: "mdi-emoticon-sad",
          color: "red",
          value: (547 / this.total) * 100,
        },
      ];
    },
  },

  async created() {
    await fakeRequest(3000);
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.reviews-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__toolbar {
    flex: 0 1 auto !important;
  }
  &__body {
    flex-grow: 1;
    padding: 15px;
  }
}

.reviews {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.reviews-avatars {
  display: flex;
  margin-top: 15px;
}

.review-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.review {
  margin-bottom: 18px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &__title {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.0125em;
  }
  &__progress {
    margin-top: 10px;
  }
}

@media screen and (max-width: 576px) {
  .reviews {
    display: block;
  }
  .review-list {
    margin-top: 30px;
  }
}
</style>