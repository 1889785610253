<script>
import { VTextField } from "vuetify/lib";

const checkWidth = (value, el, opts) => {
  const defaults = { maxWidth: "none", minWidth: "none", comfortZone: 0 };
  const options = Object.assign({}, defaults, opts);

  el.input.style.maxWidth = options.maxWidth;
  el.input.style.minWidth = options.minWidth;
  el.input.style.flexGrow = 0;

  while (el.mirror.childNodes.length) {
    el.mirror.removeChild(el.mirror.childNodes[0]);
  }

  el.mirror.appendChild(document.createTextNode(value));

  let newWidth = el.mirror.scrollWidth + options.comfortZone + 2;

  if (newWidth != el.input.scrollWidth) {
    el.input.style.width = `${newWidth}px`;
  }
};

export default {
  name: "VTextFieldExtend",

  extends: VTextField,

  props: {
    autoWidth: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    internalValue(value) {
      if (this.autoWidth) {
        checkWidth(value, this.$el, { minWidth: "100px" });
      }
    },
  },

  mounted() {
    if (this.autoWidth) {
      this.$el.mirror = document.createElement("div");
      this.$el.input = this.$el.querySelector(".v-input__control");

      this.$el.dataset.uuid = Math.random().toString(36).slice(-5);

      const styles = window.getComputedStyle(this.$el);

      Object.assign(this.$el.mirror.style, {
        position: "absolute",
        top: "0",
        left: "0",
        visibility: "hidden",
        height: "0",
        overflow: "hidden",
        whiteSpace: "pre",
        fontSize: styles.fontSize,
        fontFamily: styles.fontFamily,
        fontWeight: styles.fontWeight,
        fontStyle: styles.fontStyle,
        letterSpacing: styles.letterSpacing,
        textTransform: styles.textTransform,
      });

      this.$el.mirror.classList.add(`vue-input-autowidth-mirror-${this.$el.dataset.uuid}`);
      this.$el.mirror.setAttribute("aria-hidden", "true");

      document.body.appendChild(this.$el.mirror);
    }
  },
};
</script>