























import Component, { mixins } from "vue-class-component";

import VLogo from "@/components/VLogo.vue";
import VLocaleDropdown from "@/components/VLocaleDropdown.vue";
import VProfileDropdown from "@/components/profile/VProfileDropdown.vue";

import notify from "@/mixins/notify";

@Component({
  name: "EmptyLayout",
  components: {
    VLogo,
    VLocaleDropdown,
    VProfileDropdown,
  },
})
export default class EmptyLayout extends mixins(notify) {}
