import { Module } from "vuex";
import Cookies from "js-cookie";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AuthState } from "./types";
import { RootState } from "../types";

import { UserModel } from "@/@types/model";

export const UserSchema = (): UserModel => ({
  id: null,
  currentSupplier: null,
  avatar: null,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  role: null,
  permissions: [],
  netStatus: "OFFLINE",
  emailVerifiedAt: null,
  isFirstVisit: false,
});

export const state: AuthState = {
  user: UserSchema(),
  token: Cookies.get("token"),
};

const namespaced: boolean = true;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
