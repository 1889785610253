import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AppState } from "./types";
import { RootState } from "../types";

import { version } from "../../../package.json";
import { AppSettingsModel } from "@/@types/model";

const AppSettingsSchema = (): AppSettingsModel => ({
  isFirstConfig: false,

  country: null, // alpha3Code
  customerURL: null,
  timeFormat: null,
  firstDayOfWeek: "mon",
  defaultCurrency: null,
  defaultLanguage: "en",
  alternativeLanguages: [],
  vatRates: [],
  subdomain: "",
  linkId: null,
});

export const state = (): AppState => {
  const theme = localStorage.getItem("theme") || null;

  return {
    version,
    netStatus: "ONLINE",

    theme,
    sounds: { order: true },
    settings: AppSettingsSchema(),

    isSettingsLoading: false,
    settingsError: null,
  };
};

const namespaced: boolean = true;

export const app: Module<AppState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
