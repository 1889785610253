






















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

import VLogo from "@/components/VLogo.vue";

import { confirm } from "@/helpers/dialogs";

type LeftNavigation = {
  name?: string;
  title?: TranslateResult;
  icon?: string;
  isCan?: boolean;
  divider?: boolean;
};

@Component({
  name: "VLeftNavigation",
  components: { VLogo },
})
export default class VLeftNavigation extends Vue {
  @Prop(Boolean) readonly isMini!: boolean;

  get items(): LeftNavigation[] {
    return [
      {
        name: "dashboard",
        title: this.$t("Navigation.Dashboard"),
        icon: "mdi-view-dashboard",
      },
      {
        name: "orders",
        title: this.$t("Navigation.Orders"),
        icon: "mdi-cart-arrow-down",
      },
      { divider: true },
      {
        name: "menus",
        title: this.$t("Navigation.MyMenus"),
        icon: "mdi-format-list-text",
      },
      {
        name: "menus.archive",
        title: this.$t("Navigation.ArchivedMenus"),
        icon: "mdi-archive",
      },
      {
        name: "menus.trash",
        title: this.$t("Navigation.TrashMenus"),
        icon: "mdi-delete",
      },
      { divider: true },
      {
        name: "qrcodes",
        title: this.$t("Navigation.QRCodes"),
        icon: "mdi-qrcode-plus",
      },
      {
        name: "media",
        title: this.$t("Navigation.Media"),
        icon: "mdi-folder-multiple-image",
      },
      { divider: true },
      {
        name: "users",
        title: this.$t("Navigation.Users"),
        icon: "mdi-account-edit",
        isCan: this.$mcan(["*", "list"], "supplier:users"),
      },
      {
        name: "settings.venue",
        title: this.$t("Navigation.VenueSettings"),
        icon: "mdi-application-settings",
      },
      {
        name: "settings",
        title: this.$t("Navigation.Settings"),
        icon: "mdi-cogs",
      },
      {
        name: "legal",
        title: this.$t("Navigation.Legal"),
        icon: "mdi-alpha-l-box",
      },
    ];
  }

  async logout() {
    const isConfirm = await this.$dialog.warning(
      confirm(undefined, this.$t("Messages.AreYouSureWantToLogOut")),
    );
    if (!isConfirm) return;

    await this.$store.dispatch("auth/logout");
    this.$router.push({ name: "login" });
  }
}
