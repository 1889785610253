import { MenuModel } from "@/@types/model";
import { MutationTree } from "vuex";
import { MenuSchema } from ".";

import { MenuState, types } from "./types";

type SetMenuFieldType = { field: keyof MenuModel, payload: MenuModel[keyof MenuModel] }

export const mutations: MutationTree<MenuState> = {
  /* Fetch Menu */
  [types.REQUESTED_MENU](state) {
    state.isMenuLoading = true;
  },
  [types.REQUESTED_MENU_SUCCEEDED](state, { menu, firstCategory }) {
    state.menu = { ...menu };
    state.cacheMenu = { ...menu };
    state.selectedCategoryId = firstCategory ? firstCategory.id : null;

    state.isMenuLoading = false;
  },
  [types.REQUESTED_MENU_FAILED](state, error) {
    state.isMenuLoading = false;
    state.menuError = error;
  },

  /* Fetch Menus */
  [types.REQUESTED_MENUS](state) {
    state.isMenusLoading = true;
  },
  [types.REQUESTED_MENUS_SUCCEEDED](state, menus) {
    state.menus = menus;
    state.isMenusLoading = false;
  },
  [types.REQUESTED_MENUS_FAILED](state, error) {
    state.isMenusLoading = false;
    state.menusError = error;
  },

  /* Fetch menu settings */
  [types.REQUESTED_MENU_SETTINGS](state) {
    state.isMenuSettingsLoading = true;
  },

  [types.REQUESTED_MENU_SETTINGS_SUCCEEDED](state, menuSettings) {
    state.menuSettings = menuSettings;
    state.isMenuSettingsLoading = false;
  },

  [types.REQUESTED_MENU_SETTINGS_FAILED](state) {
    state.menuSettings = null;
    state.isMenuSettingsLoading = false;
  },

  /* Save Menu */
  [types.REQUESTED_SAVE_MENU](state) {
    state.isMenuBusy = true;
  },
  [types.REQUESTED_SAVE_MENU_SUCCEEDED](state, menu) {
    state.menu = { ...state.menu, ...menu };
    state.cacheMenu = { ...state.cacheMenu, ...menu };

    state.isMenuBusy = false;
  },
  [types.REQUESTED_SAVE_MENU_FAILED](state, error) {
    state.isMenuBusy = false;
    state.menuError = error;
  },

  /* Menus */
  [types.CLEAR_MENUS](state) {
    state.menus = [];
    state.isMenusLoading = true;
    state.menusError = null;
  },

  /* Menu */
  [types.SET_MENU](state, menu) {
    state.menu = { ...menu };
  },
  [types.SET_MENU_FIELD](state, { field, payload }: SetMenuFieldType) {
    state.menu = { ...state.menu, [field]: payload };
  },
  [types.UPDATE_MENU](state, menu) {
    state.menu = { ...state.menu, ...menu };
  },
  [types.CLEAR_MENU](state) {
    state.cacheMenu = null;
    state.menu = MenuSchema();
    state.menuSettings = null;
    state.selectedCategoryId = null;

    state.isMenuLoading = true;
    state.isMenuSettingsLoading = false;
    state.isMenuBusy = false;
    state.menuError = null;
  },

  /* Cache Menu */
  [types.SET_CACHE_MENU](state, menu) {
    state.cacheMenu = { ...menu };
  },
  [types.UPDATE_CACHE_MENU](state, menu) {
    state.cacheMenu = { ...state.cacheMenu, ...menu };
  },

  /* Dishes */
  [types.SET_DISHES]({ menu }, { categoryId, dishes }) {
    menu.categories = menu.categories.map((category) =>
      category.id === categoryId ? { ...category, dishes } : category,
    );
  },
  [types.ADD_DISH]({ menu }, { categoryId, dish }) {
    menu.categories = menu.categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          dishes: category.dishes.concat(dish),
        };
      }
      return category;
    });
  },
  [types.UPDATE_DISH]({ menu }, { categoryId, dishId, dish: data }) {
    menu.categories = menu.categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          dishes: category.dishes.map((dish) => (dish.id === dishId ? { ...dish, ...data } : dish)),
        };
      }
      return category;
    });
  },
  [types.DELETE_DISH]({ menu }, { categoryId, dishId }) {
    menu.categories = menu.categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          dishes: category.dishes.filter((dish) => dish.id !== dishId),
        };
      }
      return category;
    });
  },

  /* Categories */
  [types.ADD_CATEGORY]({ menu }, category) {
    menu.categories = menu.categories.concat(category);
  },
  [types.UPDATE_CATEGORY]({ menu }, { categoryId, data }) {
    menu.categories = menu.categories.map((item) =>
      item.id === categoryId ? { ...item, ...data } : item,
    );
  },
  [types.DELETE_CATEGORY]({ menu }, categoryId) {
    menu.categories = menu.categories.filter((category) => category.id !== categoryId);
  },

  /* Other */
  [types.SET_SELECTED_CATEGORY](state, categoryId) {
    state.selectedCategoryId = categoryId;
  },
  [types.CLEAR_SELECTED_CATEGORY](state) {
    state.selectedCategoryId = null;
  },
};
