import { v4 as uuid } from "uuid";
import { ActionTree } from "vuex";

import { NotificationsState, types } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<NotificationsState, RootState> = {
  add({ commit, state }, notification) {
    const notifications = [{ id: uuid(), ...notification }, ...state.notifications];
    commit(types.SET_NOTIFICATIONS, notifications);
  },

  deleteByGroup({ commit, state }, group) {
    const notificationsWithoutGroup = state.notifications.filter(
      (notification) => notification.group !== group,
    );
    commit(types.SET_NOTIFICATIONS, notificationsWithoutGroup);
  },

  clear({ commit }) {
    commit(types.SET_NOTIFICATIONS, []);
    commit(types.SET_NOTIFY, null);
  },
};
