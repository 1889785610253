import Cookies from "js-cookie";

import { ActionTree } from "vuex";
import { LangState, types } from "./types";
import { RootState } from "../types";

export const actions: ActionTree<LangState, RootState> = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale });

    Cookies.set("locale", locale, { expires: 365 });
  },
};
