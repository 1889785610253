import { mapGetters } from "vuex";

import { LNK_URL, CUSTOMER_URL } from "../../config";

export default {
  computed: {
    ...mapGetters({
      appSettings: "app/settings",
    }),
    qrcodeData() {
      return (qrcode) => {
        const { codeType, content, linkId } = this.qrcode || qrcode;

        let qrcodeData = null;

        const partUrl = !this.isLocal ? "~" : "";

        switch (true) {
          case codeType === "WIFI":
            qrcodeData = `WIFI:S:${content.WIFI.ssid};T:${content.WIFI.encryption};P:${content.WIFI.password};;`;
            break;
          case !!linkId:
            qrcodeData = `${LNK_URL}/${partUrl}${linkId}`;
            break;
        }

        return qrcodeData;
      };
    },
    shortLink() {
      const { linkId } = this.qrcode || {};
      const partUrl = !this.isLocal ? "~" : "";

      return linkId ? `${LNK_URL}/${partUrl}${linkId}` : "";
    },
    longLink() {
      const { subdomain } = this.appSettings;
      const { codeType, content } = this.qrcode || {};

      if (!codeType || !content) return "";

      switch (codeType) {
        case "SUPPLIER":
          return `${CUSTOMER_URL.replace("{subdomain}", subdomain)}`;
        case "TABLE":
          return `${CUSTOMER_URL.replace("{subdomain}", subdomain)}?table=${content.TABLE.table}`;
        case "ROOM":
          return `${CUSTOMER_URL.replace("{subdomain}", subdomain)}?room=${content.ROOM.room}`;
        default:
          return "";
      }
    },
  },
};
