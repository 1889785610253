<template>
  <div class="notifications">
    <v-alert
      class="notification"
      v-for="notification in dashboardNotifications"
      :key="notification.key"
      :type="notification.type"
      prominent
      dense
    >
      <div class="notification__content">
        <div class="notification__text">{{ $t(notification.text) }}</div>
        <div class="notification__actions" v-if="notification.actions.length">
          <v-btn
            v-for="action in notification.actions"
            :to="action.to"
            @click="onActionClick(action)"
            :loading="isActionInLoading(action)"
            :key="action.key"
          >
            {{ $t(action.text) }}
          </v-btn>
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "VNotifications",

  data() {
    return {
      loadingAction: null,
    };
  },

  computed: get("notifications", ["dashboardNotifications"]),

  methods: {
    isActionInLoading(action) {
      return this.loadingAction === action.key;
    },
    async onActionClick(action) {
      const handler = action.handle || action.handler;
      if (typeof handler === "function") {
        this.loadingAction = action.key;
        try {
          await handler(action);
        } catch (e) {
          console.error("error", e);
          throw e;
        } finally {
          this.loadingAction = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  padding-top: 16px;
  padding-bottom: 16px;
  &__content {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 15px;
  }
}

@media screen and (max-width: 576px) {
  .notification {
    &__content {
      grid-template-columns: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .notification {
    ::v-deep .v-icon {
      display: none;
    }
  }
}
</style>