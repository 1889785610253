<template>
  <div class="dashboard-page">
    <!-- Notification -->
    <v-notifications />

    <div class="dashboard-grid">
      <div class="dashboard-item dashboard-item--qrcode">
        <v-qrcode-card />
      </div>
      <div class="dashboard-item dashboard-item--feedbacks">
        <v-feedbacks-card />
      </div>
      <div class="dashboard-item dashboard-item--qrcode-scanned">
        <v-qrcode-scanned-card />
      </div>
      <div class="dashboard-item dashboard-item--orders-heatmap">
        <v-orders-heatmap-card />
      </div>
      <div class="dashboard-item dashboard-item--our-visitors">
        <v-our-visitors-card />
      </div>
      <div class="dashboard-item dashboard-item--reviews">
        <v-reviews-card />
      </div>
    </div>
  </div>
</template>

<script>
import VNotifications from "../components/notifications/VNotifications";
import VQRCodeCard from "../components/dashboard/VQRCodeCard";
import VQRCodeScannedCard from "../components/dashboard/VQRCodeScannedCard";
import VFeedbacksCard from "../components/dashboard/VFeedbacksCard";
import VOurVisitorsCard from "../components/dashboard/VOurVisitorsCard";
import VOrdersHeatmap from "../components/dashboard/VOrdersHeatmap";
import VReviewsCard from "../components/dashboard/VReviewsCard";

export default {
  name: "Dashboard",

  middleware: "auth",

  components: {
    VNotifications,
    "v-qrcode-card": VQRCodeCard,
    "v-qrcode-scanned-card": VQRCodeScannedCard,
    "v-feedbacks-card": VFeedbacksCard,
    "v-our-visitors-card": VOurVisitorsCard,
    "v-orders-heatmap-card": VOrdersHeatmap,
    "v-reviews-card": VReviewsCard,
  },

  metaInfo() {
    return { title: this.$t("GlobalUI.Dashboard") };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-grid {
  display: grid;
  grid-template-areas:
    "qrcode feedbacks qrcode-scanned qrcode-scanned"
    "orders-heatmap orders-heatmap orders-heatmap our-visitors"
    "reviews reviews . .";
  grid-template-columns: minmax(320px, auto) 1fr 1fr minmax(320px, 1fr);
  grid-template-rows: 400px 400px auto;
  gap: 20px;
}

.dashboard-item {
  &--qrcode {
    grid-area: qrcode;
  }
  &--feedbacks {
    grid-area: feedbacks;
  }
  &--qrcode-scanned {
    grid-area: qrcode-scanned;
  }
  &--orders-heatmap {
    grid-area: orders-heatmap;
  }
  &--our-visitors {
    grid-area: our-visitors;
  }
  &--reviews {
    grid-area: reviews;
  }
}

@media screen and (max-width: 1600px) {
  .dashboard-grid {
    grid-template-areas:
      "qrcode feedbacks qrcode-scanned qrcode-scanned"
      "orders-heatmap orders-heatmap orders-heatmap our-visitors"
      "reviews reviews reviews reviews";
  }
}

@media screen and (max-width: 1440px) {
  .dashboard-grid {
    grid-template-areas:
      "qrcode qrcode-scanned qrcode-scanned qrcode-scanned"
      "orders-heatmap orders-heatmap orders-heatmap feedbacks"
      "our-visitors reviews reviews reviews";
    grid-template-columns: minmax(320px, auto) 1fr 1fr minmax(390px, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .dashboard-grid {
    grid-template-areas:
      "qrcode qrcode-scanned qrcode-scanned qrcode-scanned"
      "orders-heatmap orders-heatmap orders-heatmap orders-heatmap"
      "our-visitors our-visitors feedbacks feedbacks"
      "reviews reviews reviews reviews";
    grid-template-columns: minmax(280px, auto) 1fr 1fr minmax(390px, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .dashboard-grid {
    grid-template-areas:
      "qrcode"
      "orders-heatmap"
      "feedbacks"
      "qrcode-scanned"
      "our-visitors"
      "reviews";
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
}
</style>
